import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

  main: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  
}));
