import { styled } from '@material-ui/styles';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

export const Staffing = styled(Box)({
  position: 'relative',
});

export const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3),
    width: '10%',
  },
  skillsHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formControlHorizontal: {
    margin: theme.spacing(1),
    width: '25%',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  addSkillsHeader: {
    marginTop: theme.spacing(3),
  },
}));
