import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Input,
  Modal,
  Fade,
  Backdrop,
  Grid,
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loading from 'components/atoms/Loading';
import Table from 'components/molecules/Table';
import RoleConfig from 'config/rateCard';
import {
  GET_RATE_CARDS,
  CREATE_RATE_CARD,
  DELETE_RATE_CARD,
  GET_ALL_ACCOUNTS,
} from 'queries';
import { getParsedObject } from 'utils/formatters';
import { useRanglrContext } from 'context/provider.ranglr';
import {
  RateCardManagement,
  Header,
  useStyles,
} from './RateCardManagement.style';
/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const RateCardManagementComponent = () => {
  const classes = useStyles();
  const [activeRateCard, setActiveRateCard] = useState();
  const [activeAccount, setActiveAccount] = useState('master');
  const [showRateModal, setShowRateModal] = useState(false);
  const [blendedRate, setBlendedRate] = useState();

  const [, ranglrDispatch] = useRanglrContext();
  const {
    loading: rateCardsLoading,
    data: { getRateCards: rateCards = [] } = {},
  } = useQuery(GET_RATE_CARDS);
  const {
    loading: accountsLoading,
    data: { getAccounts: allAccounts = [] } = [],
  } = useQuery(GET_ALL_ACCOUNTS);
  const [saveRateCard, { data: saveRateCardSuccess }] = useMutation(
    CREATE_RATE_CARD,
  );
  const [deleteRateCard, { data: deleteRateCardSuccess }] = useMutation(
    DELETE_RATE_CARD,
  );
  const accounts = [
    ...allAccounts,
    { Name: 'Goods & Services Global', Id: 'master' },
  ].sort((a, b) => {
    if (a.Name < b.Name) return -1;
    if (a.Name > b.Name) return 1;
    return 0;
  });

  // const roles = RoleConfig.map((r) => r.role);
  const domains = {};
  RoleConfig.forEach(a => {
    domains[a.role] = a.role;
  });

  useEffect(() => {
    const newRateCard = rateCards.filter(
      rc => rc.accountId === activeAccount,
    )[0];
    setActiveRateCard(newRateCard);
  }, [activeAccount, rateCards]);

  useEffect(() => {
    if (saveRateCardSuccess)
      ranglrDispatch({
        type: 'SET_MESSAGE',
        payload: { variant: 'success', message: 'Rate Card Saved' },
      });
  }, [saveRateCardSuccess]);

  useEffect(() => {
    if (deleteRateCardSuccess)
      ranglrDispatch({
        type: 'SET_MESSAGE',
        payload: { variant: 'success', message: 'Rate Card Deleted' },
      });
  }, [deleteRateCardSuccess]);

  const rates = getParsedObject(activeRateCard, 'rates', []);

  const handleRate = (type, rate) => {
    const newRates = [...rates];
    if (type === 'EDIT') {
      newRates.forEach((a, index) => {
        if (a.id === rate.id) {
          newRates[index] = rate;
        }
      });
    } else if (type === 'NEW') {
      const newRate = { ...rate };
      newRate.id = `s${Date.now()}`;
      newRates.push(newRate);
    } else if (type === 'DELETE') {
      const deleteItemIndex = newRates.findIndex(t => t.id === rate.id);
      newRates.splice(deleteItemIndex, 1);
    }
    setActiveRateCard({ ...activeRateCard, rates: newRates });
    saveRateCard({
      variables: {
        ...activeRateCard,
        rates: JSON.stringify(newRates),
      },
      refetchQueries: ['getRateCardsQuery'],
    });
  };

  const applyBlendedRate = () => {
    if (!blendedRate) return;
    const newRates = RoleConfig.map(r => ({
      title: r.role,
      rate: blendedRate,
      description: '',
    }));
    setActiveRateCard({ ...activeRateCard, rates: newRates });
    setBlendedRate();
    setShowRateModal(false);
    saveRateCard({
      variables: {
        ...activeRateCard,
        rates: JSON.stringify(newRates),
      },
      refetchQueries: ['getRateCardsQuery'],
    });
  };

  if (accountsLoading || rateCardsLoading) return <Loading />;
  return (
    <>
      <Header>
        <Typography variant="h1">Rate Card Management</Typography>
        <Box>
          <FormControl className={classes.formControl}>
            <InputLabel id="rate-card-label">Select a Rate Card</InputLabel>
            <Select
              labelId="rate-card-label"
              id="demo-simple-select-autowidth"
              value={activeAccount}
              onChange={e => setActiveAccount(e.target.value)}
            >
              {accounts.map(a => (
                <MenuItem value={a.Id}>{a.Name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Header>
      <RateCardManagement>
        {!activeRateCard && activeAccount && (
          <Button
            onClick={() =>
              saveRateCard({
                variables: {
                  accountId: activeAccount,
                  rates: '[]',
                },
                refetchQueries: ['getRateCardsQuery'],
              })
            }
          >
            Create Rate Card
          </Button>
        )}
        {activeRateCard && activeAccount && (
          <>
            <Table
              editable={{
                onRowAdd: newData =>
                  new Promise(resolve => {
                    handleRate('NEW', newData);
                    resolve();
                  }),
                onRowUpdate: newData =>
                  new Promise(resolve => {
                    handleRate('EDIT', newData);
                    resolve();
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    handleRate('DELETE', oldData);
                    resolve();
                  }),
              }}
              columns={[
                { title: 'Title', field: 'title', lookup: domains },
                { title: 'Description', field: 'description' },
                { title: 'Rate', field: 'rate', type: 'numeric' },
              ]}
              data={rates}
              title="Rate Card"
            />
            <Grid container>
              <Button onClick={() => setShowRateModal(true)}>
                Set Blended Rate
              </Button>

              <Button
                onClick={() =>
                  deleteRateCard({
                    variables: {
                      id: activeRateCard.id,
                    },
                    refetchQueries: ['getRateCardsQuery'],
                  })
                }
              >
                Delete Rate Card
              </Button>
            </Grid>
          </>
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={showRateModal}
          onClose={() => showRateModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={showRateModal}>
            <div className={classes.paper}>
              <h2>Set Blended Rate</h2>
              <Input
                value={blendedRate}
                onChange={e => setBlendedRate(e.target.value)}
              />
              <br />
              <br />
              <Button onClick={applyBlendedRate}>Save</Button>
            </div>
          </Fade>
        </Modal>
      </RateCardManagement>
    </>
  );
};

export default RateCardManagementComponent;
