import React from 'react';
import clsx from 'clsx';
import { navigate } from '@reach/router';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Input,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import Breadcrumb from 'components/molecules/Breadcrumb';
import { useRanglrContext, useAuthContext } from 'context/provider.ranglr';
import { useFlag } from 'components/organisms/Flags';
import Styles from './NavigationAppBar.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const NavigationAppBarComponent = ({ open, setOpen }) => {
  const [authState, authDispatch] = useAuthContext();
  const [ranglrState] = useRanglrContext();
  const { signOut } = authState;
  const { breadcrumb } = ranglrState;
  const isAdmin = useFlag(['officialRole']);
  const role = useFlag(['role']);
  const classes = Styles();

  const renderLogo = (
    <button
      className={clsx(classes.logoWrapper, {
        [classes.logoWrapperClosed]: !open,
      })}
      onClick={() => {
        navigate('/');
      }}
      type="button"
    >
      <Typography className={classes.logo}>{open ? 'ranglr' : 'r'}</Typography>
    </button>
  );

  const handleRoleimpersonation = e => {
    authDispatch({
      type: 'SET',
      payload: { impersonationRole: e.target.value },
    });
  };

  const renderToolbar = (
    <Toolbar
      className={clsx(classes.toolbar, {
        [classes.toolbarClosed]: !open,
      })}
    >
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={() => setOpen(!open)}
        edge="start"
        className={clsx(classes.menuButton, {
          [classes.menuButtonClosed]: !open,
        })}
      >
        <MenuIcon color="primary" />
      </IconButton>
      <Breadcrumb crumbs={breadcrumb.crumbs} currentPage={breadcrumb.currentPage} />
      {isAdmin && (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="roleimpersonation">Impersonate</InputLabel>
          <Select value={role} onChange={handleRoleimpersonation} input={<Input name="role" id="roleimpersonation" />}>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="leadership">Leadership Team</MenuItem>
            <MenuItem value="account">Account Manager</MenuItem>
            <MenuItem value="projectManager">Project Manager</MenuItem>
            <MenuItem value="staff">Staff</MenuItem>
          </Select>
        </FormControl>
      )}
      <Button type="button" onClick={signOut} size="small">
        Sign Out
      </Button>
    </Toolbar>
  );

  const renderAppBar = (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      {renderToolbar}
    </AppBar>
  );

  return (
    <>
      {renderLogo}
      {renderAppBar}
    </>
  );
};

export default NavigationAppBarComponent;
