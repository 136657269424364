import { styled, makeStyles } from '@material-ui/core/styles';

import { Box, Paper, Fab, Button } from '@material-ui/core';

export const EstimateConfigurator = styled(Box)({});

export const Header = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(9)}px`,
  borderBottom: '1px solid #BBB',
}));

export const Footer = styled(Paper)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(9)}px`,
}));

export const MarginFab = styled(Fab)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginTop: '2px',
}));

export const FabWrapper = styled('div')(() => ({
  minWidth: '56px',
  minHeight: '40px',
}));

export const ModalButtons = styled(Button)(() => ({
  flex:"0 1 auto"
}))

export const ModalButtonContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px 0`,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
}));


export const useStyles = makeStyles(theme => ({

  effortWrapper: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: `${theme.spacing(3)}px 0`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  effortItem: {
    paddingRight: theme.spacing(3),
  },
  marginRight: {
    paddingRight: theme.spacing(3),
  }
  
}));

