import gql from 'graphql-tag';

const GET_INVOICES = gql`
  query getInvoicesQuery($client_id: ID, $project_id: Int, $from: String, $to: String, $state: String) {
    getInvoices(client_id: $client_id, project_id: $project_id, from: $from, to: $to, state: $state) {
      id
      amount
      subject
      state
      due_date
      client{
        id
        name
      }
    }
  }
`;

export { GET_INVOICES };
