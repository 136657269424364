import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  redPill: {
    background: theme.palette.accentRed.main,
    color: 'white',
  },
  toolbar: {
    background: theme.palette.grey.main,
  }
}));
