import React from 'react';
import { LinearProgress } from '@material-ui/core';

import { useStyles } from './Loading.style';

const LoadingComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingWrapper}>
      <LinearProgress color="secondary" />
    </div>
  )
};

export default LoadingComponent;
