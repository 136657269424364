import React from 'react';
import XLSX from 'xlsx';

import { Button } from '@material-ui/core';
import { useBulkStoryUtils } from '../../../utils/storyUtils';
import { useStyles } from './StoryUploadButton.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const StoryUploadButton = () => {
  const { saveStories } = useBulkStoryUtils();
  const classes = useStyles();
  const onChangeHandler = event => {
    const { files } = event.target;
    const f = files[0];
    const reader = new FileReader();
    reader.onload = e => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const storiesSheet = XLSX.utils.sheet_to_json(workbook.Sheets.Stories);
      const tasksSheet = XLSX.utils.sheet_to_json(workbook.Sheets.Tasks);
      const combinedStoryTasks = storiesSheet.map((story, si) => {
        const { id: storyId } = story;
        const tasks = [];
        // console.log('TCL: StoryUploadButton -> tasksSheet', tasksSheet);
        tasksSheet.forEach((task, ti) => {
          const { id: taskId } = task;
          if (storyId === taskId) tasks.push({ ...task, id: Date.now().toString() + si + ti });
        });

        return {
          ...{ ...story, id: Date.now().toString() + si },
          tasks: JSON.stringify(tasks),
        };
      });
      saveStories(combinedStoryTasks);
    };
    reader.readAsArrayBuffer(f);
  };

  return (
    <>
      <input
        accept=".xlsx, .xls"
        className={classes.input}
        onChange={onChangeHandler}
        id="contained-button-file"
        multiple={false}
        type="file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="outlined" color="secondary" component="span" className={classes.button}>
          Upload a File
        </Button>
      </label>
    </>
  );
};

export default StoryUploadButton;
