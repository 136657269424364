import gql from 'graphql-tag';

const GET_RATE_CARDS = gql`
  query getRateCardsQuery {
    getRateCards {
      id
      rates
      accountId
    }
  }
`;

const CREATE_RATE_CARD = gql`
  mutation createRateCard(
    $id: ID
    $rates: String!
    $accountId: String!
  ) {
    createRateCard(
      id: $id
      rates: $rates
      accountId: $accountId
    ) {
      id
      rates
      accountId
    }
  }
`;


const DELETE_RATE_CARD = gql`
  mutation deleteRateCard($id: ID!) {
    deleteRateCard(id: $id) {
      id
    }
  }
`;

export { GET_RATE_CARDS, CREATE_RATE_CARD, DELETE_RATE_CARD };
