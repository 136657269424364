import React from 'react';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

import ApolloProvider from 'components/organisms/ApolloClient';
import { RanglrProvider } from 'context/provider.ranglr';

import Layout from 'components/organisms/Layout';
import PublicRoutes from './routes/routes.public';
import PrivateRoutes from './routes/routes.private';
import theme from './theme';

require('typeface-open-sans');
require('typeface-quicksand');
require('typeface-bevan');

const history = createBrowserHistory();

/**
 * App
 * @returns {XML} App
 */
const App = () => (
  <RanglrProvider history={history}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider>
        <Layout>
          <PublicRoutes history={history} />
          <PrivateRoutes history={history} />
        </Layout>
      </ApolloProvider>
    </ThemeProvider>
  </RanglrProvider>
);

export default App;
