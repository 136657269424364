import React, { useState, useEffect } from 'react';
import useEstimateUtils from 'utils/useEstimateUtils';
import { useStoryUtils } from 'utils/storyUtils';
import { Zoom, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import Table from 'components/molecules/Table';
import { CustomStoriesList, useStyles } from './CustomStoriesList.style';
/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const CustomStoriesListComponent = () => {
  const theme = useTheme();
  const { saveStories } = useStoryUtils();
  const [selectedStories, setSelectedStories] = useState([]);
  const [customStories, setCustomStories] = useState([]);
  const { getAllEstimateStories } = useEstimateUtils();
  const classes = useStyles();
  const editedStories = getAllEstimateStories();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleSelectionChange = incomingChange => {
    setSelectedStories(incomingChange);
  };

  const handleAddToGlobal = () => {
    const newStories = selectedStories.map(ss => {
      const newS = {...ss};
      delete newS.id;
      return newS;
    })
    saveStories(newStories);
    setSelectedStories([]);
    setCustomStories(editedStories);
  };

  useEffect(() => {
    if (editedStories.length > 0 && customStories.length === 0) {
      setCustomStories(editedStories);
    }
  }, [editedStories]);

  return (
    <CustomStoriesList>
      {customStories && customStories.length > 0 && (
        <>
          <Zoom
            in={selectedStories && selectedStories.length > 0}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${selectedStories && selectedStories.length > 0 ? transitionDuration.exit : 0}ms`,
            }}
          >
            <Button
              className={classes.button}
              onClick={() => {
                handleAddToGlobal();
              }}
            >
              Add To Global List
            </Button>
          </Zoom>
          <div className={classes.main}>
            <Table
              columns={[
                { title: 'Title', field: 'title' },
                { title: 'Description', field: 'description' },
                { title: 'High Effort', field: 'high' },
                { title: 'Low Effort', field: 'low' },
              ]}
              data={customStories}
              title="Custom Stories"
              options={{ selection: true }}
              onSelectionChange={handleSelectionChange}
            />
          </div>
        </>
      )}
    </CustomStoriesList>
  );
};

export { CustomStoriesList as CustomStoriesListVanlla };
export default CustomStoriesListComponent;
