import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';

import { useRanglrContext } from 'context/provider.ranglr';
import EstimationListings from 'components/organisms/EstimationListings';
import { useStyles } from './Estimations.style';

const EstimationsComponent = () => {
  const [, ranglrDispatch] = useRanglrContext();
  useEffect(() => {
    ranglrDispatch({
      type: 'SET_BREADCRUMB',
      payload: { currentPage: 'Estimations' },
    });
  }, [ranglrDispatch]);

  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h1" component="span">Estimations</Typography>
      </div>
      <div className={classes.main}>
        <EstimationListings />
      </div>
    </>
  );
};

export default EstimationsComponent;
