import React from 'react';
import { Stepper, Step, StepButton, StepLabel } from '@material-ui/core/';

import { EstimationStepper } from './EstimateStepper.style';

const getSteps = () =>  ['Create Harvest Project', 'Create Google Drive', 'Lock Estimate'];

const EstimateStepperComponent = ({
  activeStep = -1,
  updateStep,
  completed = {},
}) => {
  const steps = getSteps();

  return (
    <EstimationStepper>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
      
          return (
            <Step key={label} {...stepProps}>
              {completed[index] ? (
                <StepLabel
                  completed={completed[index]}
                >
                  {label}
                </StepLabel>
              ) : (
                <StepButton
                  completed={completed[index]}
                  onClick={() => updateStep(index)}
                >
                  {label}
                </StepButton>
              )}
            </Step>
          );
        })}
      </Stepper>
    </EstimationStepper>
  );
};

export { EstimateStepperComponent as EstimateStepperVanlla };
export default EstimateStepperComponent;
