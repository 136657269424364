import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Check from '@material-ui/icons/Check';
import FilterList from '@material-ui/icons/FilterList';
import Remove from '@material-ui/icons/Remove';
import ArrowUpward from '@material-ui/icons/ArrowUpward';

import { Clear, Edit, Delete, AddBoxRounded } from '@material-ui/icons';
import { Typography, Toolbar } from '@material-ui/core';

import { useStyles } from './Table.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const Table = ({ columns = [], data = [], title = '', styles, hideSearch = false, ...rest }) => {
  const classes = useStyles();
  const toolbarStyles = (styles && styles.toolbar) || null;
  const components = {};
  components.Toolbar = hideSearch
    ? () => (
      <Toolbar className={toolbarStyles}>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
    )
    : props => (
      <div className={toolbarStyles}>
        <MTableToolbar {...props} className={classes.toolbar} />
      </div>
    );

  return (
    <div className={classes.table}>
      <MaterialTable
        icons={{
          Check,
          DetailPanel: ChevronRight,
          Export: SaveAlt,
          Filter: FilterList,
          FirstPage,
          LastPage,
          NextPage: ChevronRight,
          PreviousPage: ChevronLeft,
          Search,
          ThirdStateCheck: Remove,
          Clear,
          ResetSearch: Clear,
          Edit,
          Delete,
          Add: AddBoxRounded,
          SortArrow: ArrowUpward,
        }}
        columns={columns}
        data={data}
        title={title}
        components={components}
        {...rest}
      />
    </div>
  );
};

export default Table;
