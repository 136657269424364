import React from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { formatMoney } from 'utils/formatters';

import { useStyles } from './OpportunitySummary.style';

const OpportunitySummaryComponent = ({ opportunityDetail }) => {
  const classes = useStyles();
  const {
    Name,
    Account: { Name: AccountName, Harvest_ID__c: HarvestId },
    Probability,
    Amount,
    SOW_Type__c,
    Start_Date__c,
    End_Date__c,
  } = opportunityDetail;

  return (
    <>
      <Paper className={classes.main}>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Typography variant="h1">
              {AccountName} / {Name} / {HarvestId}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item direction="row">
            <Typography variant="body2">Start Date</Typography>
            <Typography variant="subtitle2">{Start_Date__c}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">End Date</Typography>
            <Typography variant="subtitle2">{End_Date__c}</Typography>
          </Grid>
        </Grid>

        <Grid container direction="row">
          <Grid item>
            <Typography variant="body2">Probability</Typography>
            <Typography variant="subtitle2">{Probability}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">Amount</Typography>
            <Typography variant="subtitle2">${formatMoney(Amount)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">Deal Type</Typography>
            <Typography variant="subtitle2">{SOW_Type__c}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export { OpportunitySummaryComponent as OpportunitySummaryVanlla };
export default OpportunitySummaryComponent;
