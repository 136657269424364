import React, { useState, useEffect } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import moment from 'moment';
import { Check, Close } from '@material-ui/icons';
import { GET_OPPORTUNITIES, GET_ALL_ESTIMATES } from 'queries';

import Table from 'components/molecules/Table';

import Loading from 'components/atoms/Loading';
import { Opportunities, useStyles } from './Opportunities.style';

const getPill = (rowData, classes) => {
  let className = 'grayPill';
  if (rowData.Probability <= 100 && rowData.Probability >= 50) {
    className = 'redPill';
  }
  return <Chip label={rowData.Probability} className={classes[className]} />;
};

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const OpportunitiesComponent = () => {
  const [estimateState, setEstimateState] = useState([]);
  const { data: { getEstimates: allEstimates } = {} } = useQuery(GET_ALL_ESTIMATES);
  const { loading, data: { getOpportunities: opportunities } = {} } = useQuery(GET_OPPORTUNITIES);
  const classes = useStyles();

  useEffect(() => {
    if (allEstimates && opportunities) {
      const filteredOpportunities = opportunities.filter(
        opp => moment().format() < moment(opp.Start_Date__c, 'YYYY-MM-DD').format(),
      );
      const opps = filteredOpportunities.map(o => {
        const estimateCount = allEstimates.filter(e => e.opportunityId === o.Id).length;
        return { ...o, estimateCount };
      });

      setEstimateState(opps);
    }
  }, [allEstimates, opportunities]);

  if (loading) return <Loading />;

  return (
    <Opportunities>
      <div className={classes.header}>
        <Typography variant="h1" component="span">
          Opportunities
        </Typography>
      </div>
      <div className={classes.main}>
        <Table
          onRowClick={(e, rowData) => {
            if (rowData.Account) navigate(`/accounts/${rowData.Account.Id}/${rowData.Id}`);
          }}
          columns={[
            {
              title: 'Probability',
              field: 'Probability',
              render: rowData => getPill(rowData, classes),
              defaultSort: 'desc',
            },
            {
              title: 'Account Name',
              render: rowData => (rowData.Account ? rowData.Account.Name : null),
            },
            { title: 'Project Name', field: 'Name' },
            { title: 'Start Date', field: 'Start_Date__c' },
            { title: 'End Date', field: 'End_Date__c' },
            {
              title: 'Has Estimate',
              field: 'estimateCount',
              render: rowData => (rowData.estimateCount > 0 ? <Check /> : <Close />),
            },
          ]}
          title="Current Opportunities"
          data={estimateState}
          options={{
            pageSize: 10,
          }}
          styles={{
            toolbar: classes.toolbar,
          }}
        />
      </div>
    </Opportunities>
  );
};

export { Opportunities as OpportunitiesVanlla };
export default OpportunitiesComponent;
