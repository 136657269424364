import gql from 'graphql-tag';

const GET_ALL_ESTIMATES = gql`
  query getEstimatesQuery {
    getEstimates {
      id
      opportunityId
      accountId
      groups
      title
      collaborators
      version
      isApproved
      previousVersionId
      discount
      blendedRate
      cloudDirectory
      harvestProject
    }
    getOpportunities {
      Id
      Name
      Probability
      Ranglr_Link__c
      Amount
      SOW_Type__c
      Start_Date__c
      End_Date__c
      Account {
        Id
        Name
      }
    }
  }
`;

const GET_ESTIMATE_DETAILS = gql`
  query getEstimateQuery($id: ID!, $opportunityId: String!) {
    getEstimate(id: $id, opportunityId: $opportunityId) {
      id
      opportunityId
      accountId
      groups
      title
      collaborators
      version
      isApproved
      previousVersionId
      discount
      blendedRate
      cloudDirectory
      harvestProject
    }
  }
`;

const GET_ESTIMATE_DETAILS_PEOPLE = gql`
  query getEstimateQuery($id: ID!, $opportunityId: String!) {
    getEstimate(id: $id, opportunityId: $opportunityId) {
      id
      opportunityId
      accountId
      groups
      title
      collaborators
      version
      isApproved
      previousVersionId
      discount
      blendedRate
      cloudDirectory
      harvestProject
    }
    getEmployees {
      id
      first_name
      last_name
      is_active
      avatar_url
    }
  }
`;

const PUT_ESTIMATE = gql`
  mutation putEstimateQuery(
    $opportunityId: String!
    $id: ID!
    $groups: String!
    $totals: String
    $title: String
    $accountId: String
    $collaborators: String
    $version: Int
    $isApproved: Boolean
    $previousVersionId: ID
    $discount: Int
    $blendedRate: Int
    $cloudDirectory: String
    $harvestProject: String
  ) {
    putEstimate(
      opportunityId: $opportunityId
      id: $id
      groups: $groups
      totals: $totals
      title: $title
      accountId: $accountId
      collaborators: $collaborators
      version: $version
      isApproved: $isApproved
      previousVersionId: $previousVersionId
      discount: $discount
      blendedRate: $blendedRate
      cloudDirectory: $cloudDirectory
      harvestProject: $harvestProject
    ) {
      id
      opportunityId
      accountId
      groups
      title
      collaborators
      version
      isApproved
      previousVersionId
      discount
      blendedRate
      cloudDirectory
      harvestProject
    }
  }
`;

const CREATE_ESTIMATE = gql`
  mutation createEstimateQuery(
    $accountId: String
    $opportunityId: String!
    $title: String
    $discount: Int
    $blendedRate: Int
    $cloudDirectory: String
    $harvestProject: String
  ) {
    createEstimate(
      accountId: $accountId
      opportunityId: $opportunityId
      title: $title
      discount: $discount
      blendedRate: $blendedRate
      cloudDirectory: $cloudDirectory
      harvestProject: $harvestProject
    ) {
      id
      opportunityId
      accountId
      groups
      title
      collaborators
      version
      isApproved
      previousVersionId
      discount
      blendedRate
      cloudDirectory
      harvestProject
    }
  }
`;

const DELETE_ESTIMATE = gql`
  mutation deleteEstimateQuery($id: ID!, $opportunityId: String!) {
    deleteEstimate(id: $id, opportunityId: $opportunityId) {
      id
    }
  }
`;

export {
  GET_ALL_ESTIMATES,
  GET_ESTIMATE_DETAILS,
  PUT_ESTIMATE,
  GET_ESTIMATE_DETAILS_PEOPLE,
  CREATE_ESTIMATE,
  DELETE_ESTIMATE,
};
