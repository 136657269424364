import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_OPPORTUNITY_DETAILS } from 'queries';
import Loading from 'components/atoms/Loading';
import OpportunitySummary from 'components/molecules/OpportunitySummary';
import Estimate from 'components/molecules/Estimate';
import useEstimateUtils from 'utils/useEstimateUtils';
import { useEstimateContext } from 'context/provider.ranglr';
import { useStyles } from './OpportunityDetail.style';

const OpportunityDetailComponent = ({ accountId, opportunityId }) => {
  const [, dispatchEstimateContext] = useEstimateContext();
  const { setOpportunityEstimates } = useEstimateUtils();

  const classes = useStyles();
  const { loading, data: { getOpportunity = {} } = {} } = useQuery(
    GET_OPPORTUNITY_DETAILS,
    {
      variables: { accountId, opportunityId },
    },
  );

  useEffect(() => {
    if (accountId && opportunityId) {
      setOpportunityEstimates(accountId, opportunityId);
    }
  }, [accountId, opportunityId]);

  useEffect(() => {
    if (getOpportunity && getOpportunity.length > 0) {
      const opp = getOpportunity[0];
      dispatchEstimateContext({
        type: 'SET_CURRENT_ESTIMATE_HARVEST_ACCOUNT_ID',
        payload: { harvestId: opp.Account.Harvest_ID__c },
      });
    }
  }, [getOpportunity]);

  useEffect(() => {
    dispatchEstimateContext({
      type: 'INIT_OPPORTUNITY',
      payload: { accountId, opportunityId },
    });

    return () => {
      dispatchEstimateContext({
        type: 'INIT_OPPORTUNITY',
        payload: { opportunityEstimates: [] },
      });
    };
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <OpportunitySummary
        accountId={accountId}
        opportunityId={opportunityId}
        opportunityDetail={
          getOpportunity && getOpportunity.length > 0 ? getOpportunity[0] : {}
        }
      />
      <div className={classes.main}>
        <Estimate
          accountId={accountId}
          opportunityId={opportunityId}
          harvestId={
            getOpportunity && getOpportunity.length > 0
              ? getOpportunity[0].Harvest_ID__c
              : 0
          }
        />
      </div>
    </>
  );
};

export { OpportunityDetailComponent as OpportunityDetailVanlla };
export default OpportunityDetailComponent;
