
import { styled, makeStyles } from '@material-ui/core/styles';

import { Box} from '@material-ui/core';

export const EstimateMetrics = styled(Box)({

});

export const useStyles = makeStyles(theme => ({

  effortWrapper: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: `${theme.spacing(3)}px 0`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  effortItem: {
    paddingRight: theme.spacing(3),
  }
  
}));
