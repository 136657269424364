import React from 'react';
import { Button } from 'semantic-ui-react';

import { useFlag } from 'components/organisms/Flags';
import { useAuthContext } from 'context/provider.ranglr';

const ProtectedRouteComponent = ({ render, allowedRoles, ...rest }) => {
  const isAllowed = useFlag([allowedRoles]);
  const [authState] = useAuthContext();
  const { token, signIn } = authState;
  let authenticated = false;
  if (token) {
    authenticated = true;
  }
  if (!isAllowed && allowedRoles) {
    return null;
  }

  if (authenticated) {
    return <>{React.cloneElement(render, rest)}</>;
  }

  return <Button onClick={signIn}>Sign In</Button>;
};

export default ProtectedRouteComponent;
