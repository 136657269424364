import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ transitions, spacing }) => ({
  wrapper: {
    paddingBottom: spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logo: {
    maxHeight: 80,
  },
  leftIcon: {
    marginRight: spacing(2),
  },
  filterWrapper: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: transitions.create('max-height', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.enteringScreen,
    }),
  },
  filterWrapperExpanded: {
    maxHeight: '300px',
  },
}));
