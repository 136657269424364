import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import moment from 'moment';
import { Check, Close } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

import { GET_OPPORTUNITIES_BY_ACCOUNT } from 'queries';
import Table from 'components/molecules/Table';
import { useStyles } from './OpportunitiesList.style';

const getPill = (rowData, classes) => {
  let className = 'grayPill';
  if (rowData.Probability <= 100 && rowData.Probability >= 50) {
    className = 'redPill';
  }
  return <Chip label={rowData.Probability} className={classes[className]} />;
};

const OpportunitiesListComponent = ({ accountId }) => {

  const { data: { getOpportunitiesByAccount: opportunities = [] } = {} } = useQuery(GET_OPPORTUNITIES_BY_ACCOUNT, {
    variables: { accountId },
  });
  const classes = useStyles();
  const filteredOpportunities = opportunities.filter(
    opp => moment().format() < moment(opp.Start_Date__c, 'YYYY-MM-DD').format(),
  );
  return (
    <Table
      onRowClick={(e, rowData) => navigate(`/accounts/${accountId}/${rowData.Id}`)}
      columns={[
        { 
          title: 'Probability',
          field: 'Probability',
          render: rowData => getPill(rowData, classes),
          defaultSort: 'desc'
        },
        { title: 'Name', field: 'Name' },
        { title: 'Start Date', field: 'Start_Date__c' },
        { title: 'End Date', field: 'End_Date__c' },
        {
          title: 'Has Staffing',
          field: 'Ranglr_Link__c',
          render: rowData => (rowData.Ranglr_Link__c ? <Check/> : <Close/>),
        },
      ]}
      title='Current Opportunities'
      data={filteredOpportunities}
      options={{
        pageSize: 10
      }}
      styles={{
        toolbar: classes.toolbar
      }}
    />
  );
};

export default OpportunitiesListComponent;
