import React, { useState } from 'react';
import { FilterList } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { useStyles } from './FilterHeader.style';
/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const FilterHeaderComponent = ({ children }) => {
  const [showFilters, setShowFilters] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Button
          onClick={() => setShowFilters(!showFilters)}
          className={classes.button}
          size="small"
        >
          <FilterList className={classes.leftIcon} />
          Filter
        </Button>
      </div>
      <div className={`${classes.filterWrapper} ${showFilters ? classes.filterWrapperExpanded : ''}`}>{children}</div>
    </div>
  );
};

export default FilterHeaderComponent;
