import gql from 'graphql-tag';

const CREATE_PROJECT = gql`
  mutation createProject(
      $clientId:ID!
      $name:String!
      $isBillable:Boolean!
      $billBy:String!
      $budgetBy:String!) {
    createProject(
        clientId:$clientId
        name:$name
        isBillable:$isBillable
        billBy:$billBy
        budgetBy:$budgetBy
      ) {
        id
        name
        code
        is_active
        is_billable
        is_fixed_fee
        bill_by
        budget
        budget_by
        budget_is_monthly
        notify_when_over_budget
        over_budget_notification_percentage
        show_budget_to_all
        created_at
        updated_at
        starts_on
        ends_on
        over_budget_notification_date
        notes
        cost_budget
        cost_budget_include_expenses
        hourly_rate
        fee
        Ranglr_Link__c
    }
  }`

const GET_PROJECTS = gql`
  query getProjectsQuery {
    getProjects {
      name
      id
      is_active
      starts_on
      ends_on
      client {
        name
      }
    }
  }
`;

const GET_PROJECT_DETAILS = gql`
  query getProjectDetailsQuery($id: ID!) {
    getProject(id: $id) {
      name
      id
      cost_budget
      budget
      hourly_rate
      starts_on
      ends_on
      client {
        name
      }
      assignments {
        user {
          id
          name
        }
      }
    }
  }
`;

export { GET_PROJECTS, GET_PROJECT_DETAILS, CREATE_PROJECT };
