import React, { useState, useEffect } from 'react';
import Table from 'components/molecules/Table';
// import { useStyles } from './StoryList.style';
/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const StoryListComponent = ({ options, onSelectionChange, stories, open }) => {
  const [storiesState, setStoriesState] = useState([]);

  useEffect(() => {
    if (open) {
      const oldStories = stories.map(s => {
        const a = {...s};
        delete a.tableData;
        return a;
      })
      setStoriesState(oldStories);
    } else {
      setStoriesState([]);
    }
  }, [open]);

  return (
    <Table
      columns={[
        { title: 'Title', field: 'title' },
        { title: 'Description', field: 'description' },
        { title: 'High End Estimate', field: 'high' },
        { title: 'Low End Estimate', field: 'low' },
        {
          title: 'Number of Tasks',
          render: ({ tasks }) => (tasks ? JSON.parse(tasks).length : 0),
        },
      ]}
      data={storiesState}
      options={options}
      onSelectionChange={onSelectionChange}
      title="All Stories"
    />
  );
};

export default React.memo(StoryListComponent);
