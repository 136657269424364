
import { styled } from '@material-ui/core/styles';

import { Box} from '@material-ui/core';

export const EstimateSummary = styled(Box)({

});

export const EstimateTab = styled(Box)(({ theme, isActive }) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(4)}px`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  borderBottom: isActive ? `2px solid ${theme.palette.primary.dark}` : `2px solid transparent`,
}));

export const ZoomWrapper = styled('div')({

});

export const TabWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
})
