import React, { useRef, useEffect, useReducer } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Select,
  Container,
  FormControl,
  MenuItem,
  InputLabel,
  Input,
  Typography,
  Paper,
  Button,
  Chip,
} from '@material-ui/core';

import { GET_TAGS, CREATE_TAG_MAPPING, GET_TAG_MAPPINGS, DELETE_TAG_MAPPING } from 'queries';
import useWhyDidYouUpdate from '../../../hooks/whyUpdate';
import { useStyles } from './SkillEditor.style';

const reducer = (state, action) => {
  switch (action.type) {
  case 'SET':
    return { ...state, ...action.payload };
  default:
    throw new Error();
  }
};

const initialState = {
  skillDomains: [],
  skillLabels: [],
  selectedDomain: [],
  selectedLabel: [],
  selectedLevel: 5,
  entitySkills: [],
};

const SkillEditorComponent = ({ entityId, isHorizontalLayout, onUpdate = () => {} }) => {
  const classes = useStyles();
  let isSaveMode = false;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const { skillDomains, skillLabels, selectedDomain, selectedLabel, selectedId, selectedLevel, entitySkills } = state;
  useWhyDidYouUpdate('state', state);
  const { loading, data: { getTag = [] } = {} } = useQuery(GET_TAGS);
  const { loadingTagMappings, data: { getTagMapping = [] } = {} } = useQuery(GET_TAG_MAPPINGS);
  const [createTagMapping] = useMutation(CREATE_TAG_MAPPING, {
    variables: {
      entityId,
      tagId: selectedId,
      level: selectedLevel,
      type: 'skill',
      returnType: 'single',
    },
    refetchQueries: ['getTagMappingsQuery'],
  });
  const [deleteTagMapping] = useMutation(DELETE_TAG_MAPPING);

  const dispatchAndUpdate = localPayload => {
    if (isSaveMode) {
      onUpdate(localPayload);
      isSaveMode = false;
    }
    dispatch(localPayload);
  };

  useEffect(() => {
    if (getTag.length > 0) {
      dispatch({ type: 'SET', payload: { skillDomains: getTag.filter(tag => !tag.parentId) } });
    }
  }, [getTag]);

  useEffect(() => {
    if (getTag && getTagMapping) {
      const skills = [];
      getTagMapping
        .filter(tag => tag.entityId === entityId)
        .forEach(({ id, tagId, level }) => {
          const label = getTag.filter(tag => tag.id === tagId);
          if (label && label.length === 1) {
            skills.push({
              id,
              label: label[0].label,
              level,
              tagId,
            });
          }
        });
      if (JSON.stringify(skills) !== JSON.stringify(entitySkills)) {
        dispatchAndUpdate({
          type: 'SET',
          payload: {
            entitySkills: skills,
            entityId,
          },
        });
      }
    }
  }, [getTagMapping, getTag]);

  useEffect(() => {
    if (selectedDomain && getTag.length > 0) {
      dispatch({
        type: 'SET',
        payload: {
          skillLabels: getTag.filter(tag => tag.parentId === selectedDomain),
        },
      });
    }

  }, [selectedDomain, getTag]);

  const cDomain = useRef('');
  const cSkill = useRef('');
  const cLevel = useRef('');
  const formControlLayout = isHorizontalLayout ? classes.formControlHorizontal : classes.formControl;

  const saveSkill = event => {
    isSaveMode = true;
    event.preventDefault();
    const checkArray = entitySkills.filter(skill => skill.id === selectedId);
    if (checkArray.length < 1) {
      createTagMapping();
    }
  };

  const handleSkillClick = event => {
    event.preventDefault();
  };

  const handleSkillDelete = (event, id) => {
    event.preventDefault();
    deleteTagMapping({
      variables: {
        id,
        entityId,
      },
      refetchQueries: ['getTagMappingsQuery'],
    });
  };

  const changeDomains = event => {
    dispatch({
      type: 'SET',
      payload: {
        selectedDomain: event.target.value,
      },
    });
  };

  const changeSkill = event => {
    const skill = getTag.filter(tag => tag.id === event.target.value);
    dispatch({
      type: 'SET',
      payload: {
        selectedLabel: skill[0].label,
        selectedId: event.target.value,
      },
    });
  };

  const changeLevel = event => {
    dispatch({
      type: 'SET',
      payload: {
        selectedLevel: event.target.value,
      },
    });
  };

  if (loading || loadingTagMappings) {
    return <>LOADING</>;
  }

  return (
    <>
      <Paper className={classes.paper}>
        {entitySkills && entitySkills.length > 0 && (
          <>
            <Container className={classes.skillsHeader}>
              <Typography variant="h2">Skills</Typography>
            </Container>
            <Container className={classes.skillsHeader}>
              {entitySkills.map(({ id, label, level }) => (
                <Chip
                  key={`${id}-${label}`}
                  label={`${label}(${level})`}
                  onClick={handleSkillClick}
                  onDelete={e => handleSkillDelete(e, id)}
                  className={classes.chip}
                  variant="outlined"
                />
              ))}
            </Container>
          </>
        )}
        <Container className={classes.addSkillsHeader}>
          <Typography>Add Skill</Typography>
        </Container>
        <Container className={classes.root} autoComplete="off">
          <FormControl className={formControlLayout}>
            <InputLabel htmlFor="domain-label-placeholder">Domain</InputLabel>
            <Select
              value={selectedDomain || ''}
              placeholder="Select a domain"
              onChange={changeDomains}
              input={<Input name="domain" ref={cDomain} value="" id="domain-label-placeholder" />}
              displayEmpty
              name="domainFilter"
              className={classes.selectEmpty}
            >
              {skillDomains &&
                skillDomains.map(({ id, label }) => (
                  <MenuItem key={id} value={id}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={formControlLayout}>
            <InputLabel htmlFor="skill-label-placeholder">Skill</InputLabel>
            <Select
              value={selectedId || ''}
              placeholder="Select a domain"
              onChange={changeSkill}
              input={<Input name="domain" ref={cSkill} value="" id="skill-label-placeholder" />}
              displayEmpty
              name="skillPicker"
              className={classes.selectEmpty}
            >
              {skillLabels &&
                skillLabels.map(({ id, label }) => (
                  <MenuItem key={id} value={id} data-label={label}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={formControlLayout}>
            <InputLabel htmlFor="level-label-placeholder">Level</InputLabel>
            <Select
              value={selectedLevel || ''}
              placeholder="Select a level"
              onChange={changeLevel}
              input={<Input name="level" ref={cLevel} value="" id="level-label-placeholder" />}
              displayEmpty
              name="levelPicker"
              className={classes.selectEmpty}
            >
              {[1, 2, 3, 4, 5].map(v => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            className={classes.button}
            onClick={saveSkill}
            disabled={!selectedDomain || !selectedLabel || !selectedLevel}
          >
            Save
          </Button>
        </Container>
      </Paper>
    </>
  );
};

export default React.memo(SkillEditorComponent);
