import React, { useState } from 'react';
import { TextField, Typography, Toolbar, Box } from '@material-ui/core';
import Table from 'components/molecules/Table';
import rolesConfig from 'config/roles';

import {
  StoryEditor,
  Editor,
  Footer,
  StoryDetailsWrapper,
  StoryDetails,
  Tasks,
  ButtonComp,
  useStyles,
  ErrorText,
} from './StoryEditor.style';
import SkillEditor from '../SkillEditor';

const StoryEditorComponent = ({
  story,
  handleClose,
  handleUpdate,
  handleDelete,
  saveStory,
  roles,
}) => {
  const [currentError, setCurrentError] = useState('');
  const tempStoryId = story.id || `s${Date.now()}`;
  const classes = useStyles();
  const { title, description, tasks: taskProp = '[]', multiplier = 1 } = story;
  const tasks = typeof taskProp === 'string' ? JSON.parse(taskProp) : taskProp;
  const domains = {};
  Object.keys(rolesConfig.roles).forEach(a => {
    domains[a] = a;
  });

  const localRoles = {};
  if (roles) {
    roles.forEach(a => {
      localRoles[a] = a;
    });
  }

  const handleChange = name => event => {
    handleUpdate({ ...story, [name]: event.target.value });
  };

  const handleTask = (type, task) => {
    const tempTasks = [...tasks];
    let runningHigh = 0;
    let runningLow = 0;

    if (type === 'EDIT') {
      tempTasks.forEach((a, index) => {
        if (a.id === task.id) {
          tempTasks[index] = task;
        }
      });
    } else if (type === 'NEW') {
      const newTask = { ...task };
      newTask.id = `s${Date.now()}`;
      newTask.taskId = `t${Date.now()}`;
      tempTasks.push(newTask);
    } else if (type === 'DELETE') {
      const deleteItemIndex = tempTasks.findIndex(t => t.id === task.id);
      tempTasks.splice(deleteItemIndex, 1);
    }
    tempTasks.forEach(t => {
      runningHigh += parseInt(t.high);
      runningLow += parseInt(t.low);
    });
    handleUpdate({
      ...story,
      high: runningHigh,
      low: runningLow,
      tasks: tempTasks,
    });
  };

  const skillsUpdate = ({ payload }) => {
    const ids = payload.entityId.split('-');
    const storyId = ids[0];
    const taskId = ids[1];
    const taskWSkills = tasks.map(v => {
      if (storyId === v.id && v.taskId === taskId) {
        return { ...v, skills: payload.entitySkills };
      }
      return v;
    });
    handleUpdate({
      ...story,
      id: tempStoryId,
      tasks: JSON.stringify(taskWSkills),
    });
  };

  const blurSave = () => {
    handleUpdate({ ...story, id: tempStoryId, tasks: taskProp });
  };

  const doneButton = () => {
    saveStory(story);
    handleClose();
  };

  const columns = [
    { title: 'Title', field: 'title' },
    { title: 'Domain', field: 'domain', lookup: domains },
    { title: '# People Required', field: 'peopleRequired', type: 'numeric' },
    { title: 'Low Effort', field: 'low', type: 'numeric' },
    { title: 'High Effort', field: 'high', type: 'numeric' },
  ];

  if (roles) {
    columns.splice(2, 0, { title: 'Role', field: 'role', lookup: localRoles });
  }

  return (
    <StoryEditor>
      <Editor container spacing={3}>
        <StoryDetailsWrapper item xs={12} md={12}>
          <Toolbar>
            <Typography variant="h6">Story Details</Typography>
          </Toolbar>
          <StoryDetails>
            <TextField
              id="standard-name"
              label="Title"
              value={title}
              onChange={handleChange('title')}
              onBlur={blurSave}
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <TextField
              id="standard-multiline-static"
              label="Description"
              multiline
              rows="4"
              margin="normal"
              value={description}
              onChange={handleChange('description')}
              onBlur={blurSave}
              variant="outlined"
              fullWidth
            />
            <TextField
              id="standard-number"
              label="Multiplier"
              value={multiplier}
              onChange={handleChange('multiplier')}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              margin="normal"
            />
          </StoryDetails>
        </StoryDetailsWrapper>
        <ErrorText>
          {currentError}
        </ErrorText>
        <Tasks item xs={12} md={12}>
          <Table
            editable={{
              onRowAdd: newData =>
                new Promise(resolve => {
                  handleTask('NEW', newData);
                  resolve();
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  const { high = 0, low = 0 } = newData;
                  if (parseFloat(high) % 0.5 !== 0 || parseFloat(low) % 0.5) {
                    setCurrentError('Estimate can only be .5 hour increment.');
                    handleTask('EDIT', oldData);
                  } else {
                    if (currentError && currentError.length > 0) {
                      setCurrentError('');
                    }
                    handleTask('EDIT', newData);
                  }
                  resolve();
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  handleTask('DELETE', oldData);
                  resolve();
                }),
            }}
            detailPanel={[
              {
                tooltip: 'Show/Add Skills',
                render: rowData => (
                  <Box>
                    <SkillEditor
                      entityId={`${rowData.id}-${rowData.taskId}`}
                      isHorizontalLayout
                      onUpdate={skillsUpdate}
                    />
                  </Box>
                ),
              },
            ]}
            columns={columns}
            data={tasks}
            title="Assigned Tasks"
            styles={{
              toolbar: classes.toolbar,
            }}
          />
        </Tasks>
      </Editor>
      <Footer>
        <ButtonComp onClick={handleDelete}>Delete</ButtonComp>
        <ButtonComp onClick={doneButton} variant="contained" color="secondary">
          Done
        </ButtonComp>
      </Footer>
    </StoryEditor>
  );
};

export { StoryEditor as StoryEditorVanlla };
export default StoryEditorComponent;
