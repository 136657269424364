import React, { useState, useEffect } from 'react';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';

import { useAuthContext } from 'context/provider.ranglr';
import Login from 'components/templates/Login';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const ApolloClientComponent = ({ children }) => {
  const [client, setClient] = useState();
  const [authState] = useAuthContext();
  const { token, loaded, authenticated } = authState;

  useEffect(() => {
    if (token) {
      const httpLink = createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL,
        headers: {
          Authorization: token,
        },
        useGETForQueries: true,
      });

      const terminatingLink = split(
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query);
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        httpLink,
        httpLink,
      );

      const link = ApolloLink.from([terminatingLink]);

      const cli = new ApolloClient({
        link,
        cache: new InMemoryCache(),
      });
      setClient(cli);
    }
  }, [token]);

  if (client) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
  }

  if (loaded && !authenticated) {
    return <Login />;
  }

  return <></>;
};

export { ApolloClient as ApolloClientVanlla };
export default React.memo(ApolloClientComponent);
