import { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useEstimateContext, useRanglrContext } from 'context/provider.ranglr';
import { getParsedObject } from 'utils/formatters';
import {
  GET_ESTIMATE_DETAILS,
  GET_ALL_ESTIMATES,
  PUT_ESTIMATE,
  GET_OPPORTUNITY_DETAILS,
  CREATE_ESTIMATE,
  GET_RATE_CARDS,
} from 'queries';
import { DELETE_ESTIMATE } from 'queries/estimates';

const useEstimateUtils = () => {
  const [estimateContext, dispatchEstimateContext] = useEstimateContext();
  const [, dispatchRanglrContext] = useRanglrContext();
  const {
    currentEstimateId,
    opportunityId,
    accountId,
    currentEstimate = {},
    opportunityEstimates,
    activeGroup,
    rateCard,
  } = estimateContext;

  const {
    title,
    totals,
    collaborators,
    version,
    isApproved,
    previousVersionId,
    discount = 0,
    cloudDirectory,
    blendedRate = null,
  } = currentEstimate;

  const currentGroupArray = getParsedObject(currentEstimate, 'groups', []);

  const { data: { getEstimates: allEstimates = [] } = {} } = useQuery(
    GET_ALL_ESTIMATES,
  );

  const { data: { getRateCards: rateCards = [] } = {} } = useQuery(
    GET_RATE_CARDS,
  );

  const [saveEstimateFunction] = useMutation(PUT_ESTIMATE, {
    refetchQueries: [
      {
        query: GET_ESTIMATE_DETAILS,
        variables: { opportunityId, id: currentEstimateId },
      },
    ],
  });

  const [deleteEstimateFunction, { data: deleteEstimateSuccess }] = useMutation(
    DELETE_ESTIMATE,
  );

  const [createEstimateFunction] = useMutation(CREATE_ESTIMATE);

  useEffect(() => {
    if (allEstimates && allEstimates.length) {
      getOpportunityEstimates(allEstimates);
      if (currentEstimateId) {
        setActiveEstimate(currentEstimateId);
      }
    }
  }, [allEstimates]);

  useEffect(() => {
    if (deleteEstimateSuccess) {
      dispatchRanglrContext({
        type: 'SET_MESSAGE',
        payload: {
          message: 'Estimate Deleted Successfully',
          variant: 'success',
        },
      });
    }
  }, [deleteEstimateSuccess]);

  useEffect(() => {
    if (
      opportunityEstimates &&
      opportunityEstimates.length > 0 &&
      !currentEstimateId
    ) {
      setActiveEstimate(opportunityEstimates[0].id);
    }
  }, [opportunityEstimates]);

  const setDiscount = discountProp => {
    saveEstimate(currentGroupArray, { discount: discountProp });
  };

  const setBlendedRate = blendedRateProp => {
    saveEstimate(currentGroupArray, { blendedRate: blendedRateProp });
  };

  const setEstimateApproval = ({
    accountId: accountIdData,
    opportunityId: opportunityIdData,
    isApprovedBool,
  }) => {
    saveEstimate(currentGroupArray, {
      opportunityId: opportunityIdData,
      accountId: accountIdData,
      isApproved: isApprovedBool,
    });
  };

  const setHarvestProject = ({
    accountId: accountIdData,
    opportunityId: opportunityIdData,
    createProjectData,
  }) => {
    const hp = {
      opportunityId: opportunityIdData,
      accountId: accountIdData,
      harvestProject: JSON.stringify(createProjectData),
    };
    saveEstimate(currentGroupArray, hp);
  };

  const setCloudDirectory = ({
    opportunityId: opportunityIdData,
    accountId: accountIdData,
    cloudDirectoryData,
  }) => {
    const cd = {
      opportunityId: opportunityIdData,
      accountId: accountIdData,
      cloudDirectory: JSON.stringify(cloudDirectoryData),
    };
    saveEstimate(currentGroupArray, cd);
  };

  const getOpportunityEstimates = allEst => {
    if (opportunityId && accountId) {
      const oppEstimates = allEst.filter(
        e => e.accountId === accountId && e.opportunityId === opportunityId,
      );
      dispatchEstimateContext({
        type: 'SET',
        payload: { opportunityEstimates: oppEstimates },
      });
    }
  };

  const setOpportunityEstimates = (innAccId, inOppId) => {
    if (opportunityId && accountId) {
      const oppEstimates = allEstimates.filter(
        e => e.accountId === innAccId && e.opportunityId === inOppId,
      );
      dispatchEstimateContext({
        type: 'SET',
        payload: { opportunityEstimates: oppEstimates },
      });
    }
  };

  const setActiveEstimate = newEstimateId => {
    if (!allEstimates || !allEstimates.filter) return;

    const newActiveEstimate = allEstimates.filter(e => e.id === newEstimateId);
    let activeRateCard = [];
    if (rateCards.length > 0) {
      const masterRateCard = rateCards.filter(r => r.accountId === 'master')[0];
      const accountRateCard = rateCards.filter(
        r => r.accountId === accountId,
      )[0];
      if (masterRateCard) {
        activeRateCard = getParsedObject(masterRateCard, 'rates', []);
      }
      if (accountRateCard) {
        const accountRates = getParsedObject(accountRateCard, 'rates', []);
        accountRates.forEach(ar => {
          activeRateCard = activeRateCard.map(mr => {
            if (mr.title === ar.title) {
              return { ...mr, rate: ar.rate };
            }
            return mr;
          });
        });
      }
    }

    if (newActiveEstimate.length > 0) {
      dispatchEstimateContext({
        type: 'SET',
        payload: {
          currentEstimate: newActiveEstimate[0],
          currentEstimateId: newEstimateId,
          rateCard: activeRateCard,
        },
      });
    } else {
      dispatchEstimateContext({
        type: 'SET',
        payload: {
          currentEstimateId: newEstimateId,
          currentEstimate: {},
          rateCard: [],
        },
      });
    }
  };

  const deleteStory = story => {
    const { id } = story;
    const newGroups = currentGroupArray.map(g => {
      const newGroup = { ...g };
      let stories = getParsedObject(newGroup, 'stories', []);
      stories = stories.filter(s => s.id !== id);
      newGroup.stories = stories;
      return newGroup;
    });
    saveEstimate(newGroups);
  };

  const getAllEstimateStories = () => {
    if (!allEstimates || !allEstimates.filter) return [];

    let allCustomStories = [];
    allEstimates.forEach(est => {
      const groups = getParsedObject(est, 'groups', []);
      groups.forEach(g => {
        const eStories = g.stories
          ? g.stories.filter(s => s.isLocalUpdate)
          : [];
        allCustomStories = allCustomStories.concat(eStories);
      });
    });

    return allCustomStories;
  };

  const calculateCost = () => {
    let highCost = 0;
    let lowCost = 0;
    const EMPLOYEE_COST = 75;
    const { averageEffort, lowEffort, highEffort } = getEstimateStats();
    currentGroupArray.forEach(g => {
      const newGroup = { ...g };
      const stories = getParsedObject(newGroup, 'stories', []);
      stories.forEach(s => {
        const tasks = getParsedObject(s, 'tasks', []);
        tasks.forEach(t => {
          const { role, low, high } = t;
          let rate = rateCard.filter(rc => rc.title === role)[0];
          if (blendedRate && blendedRate > 0) rate = { rate: blendedRate };
          if (rate && rate.rate) {
            let dollar = parseInt(rate.rate);
            if (discount && discount > 0) dollar *= (100 - discount) / 100;
            lowCost += dollar * low;
            highCost += dollar * high;
          }
        });
      });
    });

    const averageCost = ((highCost + lowCost) / 2).toFixed(2);
    const averageCompanyCost = (averageEffort * EMPLOYEE_COST).toFixed(2);
    const averageRevenue = (averageCost - averageCompanyCost).toFixed(2);
    const highRevenue = (highCost - highEffort * EMPLOYEE_COST).toFixed(2);
    const lowRevenue = (lowCost - lowEffort * EMPLOYEE_COST).toFixed(2);

    return {
      highCost,
      lowCost,
      averageCost,
      averageCompanyCost,
      averageRevenue,
      averageMargin: averageCost > 0 ? (averageRevenue / averageCost) * 100 : 0,
      lowMargin: highCost > 0 ? (highRevenue / highCost) * 100 : 0,
      highMargin: lowCost > 0 ? (lowRevenue / lowCost) * 100 : 0,
    };
  };

  const getEstimateStats = () => {
    let lowEffort = 0;
    let highEffort = 0;

    currentGroupArray.forEach(g => {
      const newGroup = { ...g };
      const stories = getParsedObject(newGroup, 'stories', []);
      stories.forEach(s => {
        const { low, high, multiplier = 1 } = s;
        if (low && high) {
          lowEffort += low * multiplier;
          highEffort += high * multiplier;
        }
      });
    });

    return {
      lowEffort: lowEffort.toFixed(1),
      highEffort: highEffort.toFixed(1),
      averageEffort: ((lowEffort + highEffort) / 2).toFixed(2),
    };
  };

  const saveStory = story => {
    const { id } = story;
    const newGroups = currentGroupArray.map(g => {
      const newGroup = { ...g };
      let stories = getParsedObject(newGroup, 'stories', []);
      stories = stories.map(s => (s.id !== id ? s : story));
      newGroup.stories = stories;
      return newGroup;
    });
    saveEstimate(newGroups);
  };

  const deleteTask = task => {
    const { id: taskId } = task;
    const newGroups = currentGroupArray.map(g => {
      const newGroup = { ...g };
      const stories = getParsedObject(newGroup, 'stories', []);
      const newStories = stories.map(s => {
        const newStory = { ...s };
        const tasks = getParsedObject(newStory, 'tasks', []);
        newStory.tasks = tasks.filter(t => t.id !== taskId);
        return newStory;
      });
      newGroup.stories = newStories;
      return newGroup;
    });
    saveEstimate(newGroups);
  };

  const saveEstimate = (groupArray, otherProps = {}) => {
    const { newId, newTitle, ...others } = otherProps;
    const updatedEstimate = {
      opportunityId,
      accountId,
      id: newId || currentEstimateId,
      title: newTitle || title,
      groups: JSON.stringify(groupArray),
      totals,
      collaborators,
      version,
      isApproved,
      previousVersionId: newId ? currentEstimateId : previousVersionId,
      discount,
      blendedRate,
      cloudDirectory,
      ...others,
    };

    dispatchEstimateContext({
      type: 'SET',
      payload: { currentEstimate: updatedEstimate },
    });

    saveEstimateFunction({
      variables: updatedEstimate,
      refetchQueries: [
        {
          query: GET_ALL_ESTIMATES,
        },
      ],
    });
  };

  const createNewEstimate = estimateTitle => {
    createEstimateFunction({
      variables: { accountId, opportunityId, title: estimateTitle },
      refetchQueries: [
        {
          query: GET_OPPORTUNITY_DETAILS,
          variables: { accountId, opportunityId },
        },
      ],
    });
  };

  const copyExistingEstimate = estimateTitle => {
    saveEstimate(currentGroupArray, {
      newId: `e${Date.now()}-${opportunityId}`,
      newTitle: estimateTitle,
    });
  };

  const deleteEstimate = id => {
    dispatchEstimateContext({
      type: 'SET',
      payload: { currentEstimate: {} },
    });
    deleteEstimateFunction({
      variables: { opportunityId, id },
      refetchQueries: [
        {
          query: GET_ALL_ESTIMATES,
        },
      ],
    });
  };

  const addGroup = name => {
    const groupName =
      name && typeof name === 'string' && name.length > 0 ? name : 'New Group';
    const newGroups = currentGroupArray ? [...currentGroupArray] : [];
    newGroups.push({ id: `s${Date.now()}`, name: groupName });
    saveEstimate(newGroups);
  };

  const saveGroupName = ({ group, newName }) => {
    const { id } = group;
    const newGroups = [...currentGroupArray];
    newGroups.forEach(g => {
      if (g.id === id) {
        // eslint-disable-next-line no-param-reassign
        g.name = newName;
      }
    });
    saveEstimate(newGroups);
  };

  const deleteGroup = ({ id: groupId }) => {
    let newGroups = [...currentGroupArray];
    newGroups = newGroups.filter(v => v.id !== groupId);
    saveEstimate(newGroups);
  };

  const getCurrentEstimate = () => {
    const outEstimate = { ...currentEstimate };
    outEstimate.estimate = currentGroupArray;
    return outEstimate;
  };

  const saveStoriesToGroup = (newStories, groupId) => {
    if (activeGroup || groupId) {
      updateGroupStories({
        id: (activeGroup && activeGroup.id) || groupId,
        newStories,
      });
    }
  };

  const updateEstimateName = (id, newName) => {
    saveEstimate(currentGroupArray, { newId: id, newTitle: newName });
  };

  const updateGroupStories = ({ id, newStories }) => {
    const newGroups = currentGroupArray.map(v => {
      if (v.id === id) {
        const allStories = newStories.concat(v.stories || []);
        return {
          ...v,
          stories: allStories,
        };
      }
      return v;
    });
    dispatchEstimateContext({ type: 'CLOSE_STORY_MODAL' });
    saveEstimate(newGroups);
  };

  const getOppEstimates = () => opportunityEstimates || [];

  return {
    getCurrentEstimate,
    createNewEstimate,
    addGroup,
    getOppEstimates,
    setActiveEstimate,
    saveStoriesToGroup,
    deleteGroup,
    deleteEstimate,
    deleteTask,
    deleteStory,
    saveStory,
    saveGroupName,
    copyExistingEstimate,
    updateEstimateName,
    getAllEstimateStories,
    getEstimateStats,
    calculateCost,
    setDiscount,
    setCloudDirectory,
    setHarvestProject,
    setEstimateApproval,
    setOpportunityEstimates,
    setBlendedRate,
  };
};

export default useEstimateUtils;
