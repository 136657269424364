import { colors } from '../colors';

export default {
  paper: {
    background: colors.primary.main,
  },
  paperAnchorLeft: {
    borderRight: 'none',
  },
  paperAnchorDockedLeft: {
    borderRight: 'none',
  },
}
