import gql from 'graphql-tag';

const getTimeCardsByGroupQuery = `
  getTimeCardsByGroup(
    from: $from
    to: $to
    group: "user"
    groupId: $groupId
  ) {
    clientName
    userName
    hours
    date
    taskName
    projectName
    billable
    costRate
    billableRate
  }
`;

const getAllEmployeesQuery = `
getEmployees {
  id
  first_name
  last_name
  email
  avatar_url
  is_active
  is_contractor
  cost_rate
  default_hourly_rate
  roles
}`;

const getEmployeeQuery = `
  getEmployee(id: $id) {
    id
    first_name
    last_name
    email
    avatar_url
    telephone
    timezone
    is_contractor
    weekly_capacity
    roles
    assignments {
      client {
        name
      }
      project {
        id
        name
        starts_on
        ends_on
      }
    }
  }
`;

const GET_EMPLOYEE_DETAILS = gql`
  query getEmployeeDetails($id: ID!) {
    getEmployee(id: $id) {
      id
      first_name
      last_name
      email
      avatar_url
      telephone
      timezone
      is_contractor
      weekly_capacity
      roles
      assignments {
        client {
          name
        }
        project {
          id
          name
        }
      }
    }
  }
`;

const GET_EMPLOYEE_DETAILS_AND_TIMECARD_BY_USER = gql`
  query getEmployeeDetailsAndTimeCardByUser(
    $id: ID!
    $from: String!
    $to: String!
    $groupId: Int!
  ) {
    ${getTimeCardsByGroupQuery}
    ${getEmployeeQuery}
  }
`;

const GET_ALL_EMPLOYEES = gql`
  query getAllEmployees {
    ${getAllEmployeesQuery}
  }
`;

export {
  GET_EMPLOYEE_DETAILS,
  GET_ALL_EMPLOYEES,
  GET_EMPLOYEE_DETAILS_AND_TIMECARD_BY_USER,
  getAllEmployeesQuery,
};
