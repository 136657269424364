import React from 'react';
import { Save, Cancel } from '@material-ui/icons';
import { useQuery } from '@apollo/react-hooks';
import StoryList from 'components/molecules/StoryList';
import { GET_STORIES } from 'queries/stories';
import { useEstimateContext } from 'context/provider.ranglr';
import useEstimateUtils from 'utils/useEstimateUtils';
import {
  StoryListingModal,
  Footer,
  FabButton,
} from './StoryListingModal.style';
import Modal from '../Modal';

const StoryListingModalComponent = () => {
  const [estimateState, estimateDispatch] = useEstimateContext();
  const { currentStorySelection, storyModalOpen } = estimateState;
  const { saveStoriesToGroup } = useEstimateUtils();

  const { data: { getStories: stories = [] } = {} } = useQuery(GET_STORIES);

  const handleSelectionChange = newSelection => {
    const newItems = newSelection.map(s => {
      const newS = { ...s };
      newS.multiplier = 1;
      newS.id = `s${Date.now()}`;
      delete newS.tableData;
      return newS;
    });
    estimateDispatch({
      type: 'SET_STORIES_TO_ADD_TO_GROUPS',
      payload: { currentStorySelection: newItems },
    });
  };

  const handleModalClose = () => {
    estimateDispatch({ type: 'CLOSE_STORY_MODAL' });
  };

  return (
    <Modal
      noPaddingw
      isOpen={storyModalOpen}
      handleClose={handleModalClose}
      title="Add Stories To Group"
    >
      <StoryList
        open={storyModalOpen}
        stories={stories}
        options={{ selection: true }}
        onSelectionChange={handleSelectionChange}
      />
      <Footer>
        <FabButton onClick={handleModalClose}>
          <Cancel />
        </FabButton>
        <FabButton onClick={() => saveStoriesToGroup(currentStorySelection)}>
          <Save />
        </FabButton>
      </Footer>
    </Modal>
  );
};
export { StoryListingModal as StoryListingModalVanlla };
export default StoryListingModalComponent;
