import { styled, makeStyles } from '@material-ui/core/styles';

import { Box, Paper} from '@material-ui/core';

export const RateCardManagement = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
 
}));

export const Header = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 250,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
