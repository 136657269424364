import * as allEmployeeQueries from './employee';
import * as allStoryQueries from './stories';
import * as estimatesQueries from './estimates';
import * as accountQueries from './accounts';
import * as opportunityQueries from './opportunities';
import * as invoicesQueries from './invoices';
import * as projectsQueries from './projects';
import * as timeQueries from './time';
import * as capacityQueries from './capacity';
import * as gsuiteQueries from './gsuite';
import * as permissionQueries from './permissions';
import * as tagQueries from './tags';
import * as rateCards from './rateCards';

export const { GET_RATE_CARDS, CREATE_RATE_CARD, DELETE_RATE_CARD } = rateCards;
export const { GET_ALL_ACCOUNTS, GET_ACCOUNTS_ESTIMATES, GET_ACCOUNTS_FORECAST } = accountQueries;
export const { GET_PERMISSIONS, SAVE_PERMISSION } = permissionQueries;
export const {
  GET_EMPLOYEE_DETAILS,
  GET_ALL_EMPLOYEES,
  GET_EMPLOYEE_DETAILS_AND_TIMECARD_BY_USER,
} = allEmployeeQueries;
export const { 
  GET_STORIES,
  SAVE_STORY,
  SAVE_BULK_STORIES,
  DELETE_STORY,
} = allStoryQueries;
export const {
  GET_ALL_ESTIMATES,
  GET_ESTIMATE_DETAILS,
  PUT_ESTIMATE,
  GET_ESTIMATE_DETAILS_PEOPLE,
  CREATE_ESTIMATE,
  DELETE_ESTIMATE,
} = estimatesQueries;
export const {
  GET_OPPORTUNITY_DETAILS,
  GET_OPPORTUNITIES_BY_ACCOUNT,
  CREATE_STAFFING_PLAN_LINK,
  GET_OPPORTUNITIES,
} = opportunityQueries;
export const { GET_INVOICES } = invoicesQueries;
export const { GET_PROJECTS, GET_PROJECT_DETAILS, CREATE_PROJECT } = projectsQueries;
export const {
  GET_TIMECARDS_BY_GROUP,
  GET_TIMECARDS_BY_PROJECT,
  GET_ALL_TIMECARDS_BY_DATE,
  GET_ALL_TIMECARDS_WITH_RANGES,
  GET_TOP_PERFORMERS,
} = timeQueries;
export const { GET_ALL_STAFFING_PLANS } = capacityQueries;
export const { GET_GSUITE_EMPLOYEES, GET_GSUITE_EMPLOYEE, CREATE_CLOUD_DIRECTORY } = gsuiteQueries;
export const {
  GET_TAGS,
  UPDATE_TAG,
  DELETE_TAG,
  GET_TAG_MAPPINGS,
  CREATE_TAG_MAPPING,
  DELETE_TAG_MAPPING,
} = tagQueries;
