import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    backgroundColor: 'white',
    padding: 30,
  },
  main: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    overflow: 'hidden',
    '& .MuiGrid-container': {
      marginBottom: theme.spacing(3),
    },
    '& .MuiGrid-item': {
      marginRight: theme.spacing(3),
    }
  },
}));
