import gql from 'graphql-tag';

const getOpportunities = `
  getOpportunities {
    Id
    Name
    Probability
    Ranglr_Link__c
    Amount
    SOW_Type__c
    Start_Date__c
    End_Date__c
    Account {
      Id
      Name
    }
  }
`;

const GET_OPPORTUNITIES = gql`
query getOpportunitiesQuery {
  ${getOpportunities}
}`;

const GET_OPPORTUNITY_DETAILS = gql`
  query getOpportunityQuery($accountId: ID!, $opportunityId: ID!) {
    getOpportunity(accountId: $accountId, opportunityId: $opportunityId) {
      Id
      Name
      Probability
      Ranglr_Link__c
      Amount
      SOW_Type__c
      Start_Date__c
      End_Date__c
      Account {
        Id
        Name
        logo_url__c
        Harvest_ID__c
      }
    }
    getStories {
      id
      title
      description
      high
      low
      domain
      tasks
    }
    getEstimates {
      id
      groups
      totals
      opportunityId
      title
      collaborators
    }
  }
`;

const GET_OPPORTUNITIES_BY_ACCOUNT = gql`
  query getOpportunitiesByAccountQuery($accountId: ID!) {
    getOpportunitiesByAccount(accountId: $accountId) {
      Id
      Name
      Probability
      Ranglr_Link__c
      Amount
      SOW_Type__c
      Start_Date__c
      End_Date__c
    }
  }
`;

const CREATE_STAFFING_PLAN_LINK = gql`
  mutation createStaffingPlanLinkQuery($opportunityId: ID!, $link: String!) {
    createStaffingPlanLink(opportunityId: $opportunityId, link: $link) {
      Id
    }
  }
`;

export {
  GET_OPPORTUNITIES,
  GET_OPPORTUNITY_DETAILS,
  GET_OPPORTUNITIES_BY_ACCOUNT,
  getOpportunities,
  CREATE_STAFFING_PLAN_LINK,
};
