import { createMuiTheme } from '@material-ui/core/styles';
import { typography } from './typography';
import { palette } from './colors';
import custom from './custom';

export default createMuiTheme({
  typography,
  palette,
  overrides: custom,
});
