import gql from 'graphql-tag';
import { getAllEmployeesQuery } from './employee';
import { getOpportunities } from './opportunities';

const GET_ALL_STAFFING_PLANS = gql`
  query getEstimates {
    getEstimates {
      id
      groups
    }
    ${getAllEmployeesQuery}
    ${getOpportunities}
  }
`;

export { GET_ALL_STAFFING_PLANS };
