import button from './button';
import paper from './paper';
import toolbar from './toolbar';
import drawer from './drawer';
import table from './table';
import tableRow from './tableRow';
import appBar from './appBar';
import grid from './grid';

export default {
  MuiButton: button,
  MuiPaper: paper,
  MuiToolbar: toolbar,
  MuiDrawer: drawer,
  MuiTable: table,
  MuiTableRow: tableRow,
  MuiAppBar: appBar,
  MuiGrid: grid,
}
