import { styled, makeStyles } from '@material-ui/core/styles';
import { Chip, Box, Grid, Button, Typography } from '@material-ui/core';

export const StoryEditor = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(1)}px 0`,
}));

export const ErrorText = styled(Typography)(({theme}) => ({
  padding: theme.spacing(3),
}))

export const Editor = styled(Grid)(() => ({}));

export const StoryDetailsWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const StoryDetails = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
}));

export const Tasks = styled(Grid)(() => ({
  
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  fledDirection: 'row',
  justifyContent: 'flex-end',
  padding: `${theme.spacing(2)}px 0 0 0`,
}));

export const ButtonComp = styled(Button)(({ theme }) => ({
  margin: `0 ${theme.spacing(1)}px 0 0`,
}));

export const Pill = styled(Chip)(({ theme }) => ({
  marginRight: `${theme.spacing(1)}px`,
}));

export const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  toolbar: {
    backgroundColor: theme.palette.grey.main,
  }
}));
