module.exports = {
  roles: [
    {
      key: 'admin',
      display: 'Admin',
      level: 100,
    },
    {
      key: 'leadership',
      display: 'Leadership Team',
      level: 99,
    },
    {
      key: 'account',
      display: 'Account Manager',
      level: 2,
    },
    {
      key: 'projectManager',
      display: 'Project Manager',
      level: 1,
    },
    {
      key: 'staff',
      display: 'Staff',
      level: 0,
    },
  ],
}
  