import gql from 'graphql-tag';

const GET_ACCOUNTS_FORECAST = gql`
  query getAccountForcastQuery($accountId: ID, $periodStart: String!, $periodEnd: String!) {
    getAccountsForecast(accountId: $accountId, periodStart: $periodStart, periodEnd: $periodEnd) {
      AmountForecasted
      AmountClosed
      Accounts {
        Id
        AmountClosed
        AmountForecasted
        Name
      }
    }
  }
`;

const GET_ACCOUNTS_ESTIMATES = gql`
  query getAllAccountsQuery {
    getAccounts {
      Name
      logo_url__c
      Id
      Harvest_ID__c
      Opportunities {
        Probability
        Name
        Ranglr_Link__c
        Id
        Start_Date__c
        End_Date__c
      }
    }
    getEstimates {
      id
      groups
      totals
    }
  }
`;

const GET_ALL_ACCOUNTS = gql`
  query getAllAccountsQuery {
    getAccounts {
      Name
      logo_url__c
      Id
      Harvest_ID__c
    }
  }
`;

export { GET_ALL_ACCOUNTS, GET_ACCOUNTS_FORECAST, GET_ACCOUNTS_ESTIMATES };
