const initialState = {
  token: null,
  authenticated: false,
  user: null,
  loaded: false,
  authStore: null,
  signIn: null,
  signOut: null,
  attributes: {},
  userId: null,
  impersonationRole: null,
  permissions: [],
};

const reducer = (state, action) => {
  switch (action.type) {
  case 'SET':
    return { ...state, ...action.payload };
  default:
    throw new Error(`Unhandled action type in auth reducer for: ${action.type}`);
  }
};

export {
  reducer,
  initialState,
}
