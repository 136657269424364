import moment from 'moment';

export const formatMoney = n => {
  let j;
  const c = 2;
  const d = '.';
  const t = ',';
  const s = n < 0 ? '-' : '';
  const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)), 10)); // eslint-disable-line no-param-reassign

  j = (j = i.length) > 3 ? j % 3 : 0; // eslint-disable-line no-cond-assign

  return (
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  );
};

export const truncate = (string, limit) => {
  if (!string) return '';

  if (string.length < limit) {
    return string;
  }
  
  let trimmedString = string.substr(0, limit);
  trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
  return `${trimmedString}...`;
};

export const kFormat = value => Math.round(value / 1000).toFixed(1);
export const percFormat = value => Math.round(value);

export const getParsedObject = (obj, key, def) => {
  if (!obj) return def;
  return obj && obj[key] && typeof obj[key] === 'string' ? JSON.parse(obj[key]) : obj[key] || def;
};

export const putPeopleToDates = peopleObject => {
  const dateObject = {};
  Object.keys(peopleObject).forEach(key => {
    const personId = key;
    const tasks = peopleObject[personId];
    tasks.forEach(task => {
      const tempTask = { ...task };
      delete tempTask.staffing;
      const { start, end } = task;
      let startDate = moment(start);
      const endDate = moment(end);

      while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
        const tempDate = startDate.format('dddd MM/DD/YYYY');

        if (startDate.day() !== 0 && startDate.day() !== 6) {
          if (!dateObject[tempDate]) {
            dateObject[tempDate] = [];
          }
          dateObject[tempDate].push({
            person: key,
            ...tempTask,
          });
        }
        startDate = startDate.add(1, 'd');
      }
    });
  });
  return dateObject;
};

export const getPeopleObjectWithTasks = estimateObject => {
  const peopleObject = {};
  if (!estimateObject || !estimateObject.forEach) return peopleObject;
  estimateObject.forEach(est => {
    const { stories } = est;
    if (stories) {
      stories.forEach(story => {
        const tasks = getParsedObject(story, 'tasks', []);
        tasks.forEach(task => {
          if (task.staffing) {
            const staffing = getParsedObject(task, 'staffing', []);
            staffing.forEach(st => {
              if (!peopleObject[st.id]) peopleObject[st.id] = [];
              peopleObject[st.id].push({
                ...task,
              });
            });
          }
        });
      });
    }
  });
  return peopleObject;
};

export const formatDate = date => {
  const day = date.getDay() < 10 ? `0${date.getDay()}` : date.getDay();
  const month = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
  const year = date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear();
  return `${day}, ${month} ${year}`;
};

export const currentRev = arr => {
  const OPEN = 'open';
  const PAID = 'paid';
  const DRAFT = 'draft';
  const CLOSED = 'closed';
  const totals = {};

  totals[OPEN] = 0;
  totals[PAID] = 0;
  totals[DRAFT] = 0;
  totals[CLOSED] = 0;

  arr.forEach(item => {
    totals[item.state] += item.amount;
  });

  return {
    data: [
      {
        name: 'Paid',
        y: totals[PAID],
      },
      {
        name: 'Open',
        y: totals[OPEN],
      },
    ],
  };
};
