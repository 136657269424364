import { SAVE_STORY, SAVE_BULK_STORIES } from 'queries';
import { useMutation } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';

import { useRanglrContext } from 'context/provider.ranglr';

const useStoryUtils = () => {
  const [count, setCount] = useState(-1);
  const [stories, setStories] = useState([]);
  const [saveStory, { data }] = useMutation(SAVE_STORY);

  useEffect(() => {
    if (count > -1) setCount(count + 1);
  }, [data]);

  useEffect(() => {
    if (count > -1 && stories && stories[count]) {
      saveStory({
        variables: stories[count],
        refetchQueries: ['getStoriesQuery'],
      });
    }
  }, [count]);

  const saveStories = storiesParam => {
    setStories(storiesParam);
    setCount(0);
  };

  return { saveStories };
};

const useBulkStoryUtils = () => {
  const [, ranglrDispatch] = useRanglrContext();
  const [stories, setStories] = useState([]);
  const [saveBulkStories, { data }] = useMutation(SAVE_BULK_STORIES);

  useEffect(() => {
    if (data) {
      ranglrDispatch({ type: 'SET_MESSAGE', payload: { variant: 'success', message: 'Stories Uploaded' } });
    };
  }, [data, ranglrDispatch]);

  useEffect(() => {
    if (stories.length > 0) {
      saveBulkStories({
        variables: { stories },
        refetchQueries: ['getStoriesQuery'],
      });
    }
  }, [stories]);

  const saveStories = storiesParam => setStories(storiesParam);

  return { saveStories };
};

export {
  useStoryUtils,
  useBulkStoryUtils,
}
