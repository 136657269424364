import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => {
  const {
    transitions,
    palette,
    breakpoints,
    spacing,
  } = theme;

  const toolbarHeights = {
    med: 56,
    xs: 48,
    sm: 64,
  }

  const drawer = {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
      paddingTop: toolbarHeights.med,
      [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
        paddingTop: toolbarHeights.xs,
      },
      [breakpoints.up('sm')]: {
        paddingTop: toolbarHeights.sm,
      },
    },
    drawerClose: {
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: spacing(7) + 1,
      paddingTop: toolbarHeights.med,
      [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
        paddingTop: toolbarHeights.xs,
        width: spacing(9) + 1,
      },
      [breakpoints.up('sm')]: {
        paddingTop: toolbarHeights.sm,
        width: spacing(9) + 1,
      },
    },
    icon: {
      fontSize: 25,
      color: palette.grey.main,
    },
    list: {
      borderTop: `1px solid ${palette.primary.light}`,
      padding: 0,
    },
    listItem: {
      paddingLeft: 20,
    },
    listItemActive: {
      backgroundColor: palette.primary.dark,
    },
    listItemActiveClosed: {
      backgroundColor: palette.secondary.dark,
    },
    listItemText: {
      textTransform: 'uppercase',
      color: palette.primary.contrastText,
    },
    listItemHighlight: {
      width: 10,
      height: '100%',
      backgroundColor: palette.secondary.main,
      position: 'absolute',
      top: 0,
      left: 0,
    }
  }

  return {
    ...drawer,
  }
});

export default useStyles;
