import React, { useState } from 'react';
import useEstimateUtils from 'utils/useEstimateUtils';
import { Grid, Typography, TextField, Box, IconButton, Zoom } from '@material-ui/core';
import { Delete, Add, Edit, Done, Cancel } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

import CreateEstimateDialog from 'components/molecules/CreateEstimateDialog';

import { EstimateSummary, EstimateTab, ZoomWrapper, TabWrapper } from './EstimateSummary.style';

const EstimateSummaryComponent = () => {
  const theme = useTheme();
  const [edit, setEdit] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [estimateTitle, setEstimateTitle] = useState('');
  const [copyDialog, setCopyDialog] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const {
    getOppEstimates,
    setActiveEstimate,
    getCurrentEstimate,
    deleteEstimate,
    createNewEstimate,
    copyExistingEstimate,
    updateEstimateName,
  } = useEstimateUtils();

  const allEstimates = getOppEstimates();
  const currentEstimate = getCurrentEstimate();
  const handleTabChange = newId => {
    setActiveEstimate(newId);
  };

  const handleSubmit = () => {
    if (copyDialog) {
      copyExistingEstimate(estimateTitle);
    } else {
      createNewEstimate(estimateTitle);
    }
    setDialogOpen(false);
    setCopyDialog(false);
    setEstimateTitle('');
  };

  return (
    <EstimateSummary>
      <Grid container justify="space-between" alignItems="center">
        <TabWrapper>
          {allEstimates.map(est => (
            <EstimateTab
              isActive={est.id === currentEstimate.id}
              onClick={() => handleTabChange(est.id)}
              onMouseEnter={() => setShowEditButton(est.id)}
              onMouseLeave={() => setShowEditButton(false)}
            >
              {edit === est.id ? (
                <TextField value={estimateTitle} onChange={e => setEstimateTitle(e.target.value)} />
              ) : (
                <Typography>{est.title}</Typography>
              )}
              <ZoomWrapper>
                <Zoom in={showEditButton === est.id} timeout={transitionDuration}>
                  {edit === est.id ? (
                    <>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          updateEstimateName(est.id, estimateTitle);
                          setEdit('');
                          setEstimateTitle('');
                        }}
                        size="small"
                      >
                        <Done />
                      </IconButton>
                      <IconButton
                        onClick={e => {
                          e.stopPropagation();
                          setEstimateTitle('');
                          setEdit('');
                        }}
                        size="small"
                      >
                        <Cancel />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      onClick={e => {
                        e.stopPropagation();
                        setEstimateTitle(est.title);
                        setEdit(est.id);
                      }}
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                  )}
                </Zoom>
              </ZoomWrapper>
            </EstimateTab>
          ))}
        </TabWrapper>
        <Box>
          <IconButton size="small" onClick={() => setDialogOpen(true)}>
            <Add />
          </IconButton>
          {/* <IconButton size="small" onClick={() => setCopyDialog(true)}>
            <FileCopy />
          </IconButton> */}
          <IconButton onClick={() => deleteEstimate(currentEstimate.id)} size="small">
            <Delete />
          </IconButton>
        </Box>
      </Grid>
      <CreateEstimateDialog
        open={dialogOpen || copyDialog}
        handleClose={() => {
          setDialogOpen(false);
          setCopyDialog(false);
        }}
        updateParent={e => setEstimateTitle(e.target.value)}
        handleSubmit={handleSubmit}
        title={estimateTitle}
      />
    </EstimateSummary>
  );
};

export { EstimateSummary as EstimateSummaryVanlla };
export default EstimateSummaryComponent;
