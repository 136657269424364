import { styled,  } from '@material-ui/styles';
import { Box, Fab,  } from '@material-ui/core';

export const StoryListingModal = styled(Box)(({ theme }) => ({
  background: 'white',
  margin: `${theme.spacing(2)}px 0`,
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: `${theme.spacing(2)}px`,
}));

export const FabButton = styled(Fab)(({ theme }) => ({
  marginLeft: `${theme.spacing(2)}px`,
}));
