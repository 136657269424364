import React from 'react';
import useEstimateUtils from 'utils/useEstimateUtils';
import { Typography, Grid } from '@material-ui/core';
import { formatMoney, percFormat } from 'utils/formatters';
import { EstimateMetrics, useStyles } from './EstimateMetrics.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const EstimateMetricsComponent = ({ minimal }) => {
  const classes = useStyles();
  const { getEstimateStats, calculateCost } = useEstimateUtils();

  const { lowEffort, highEffort, averageEffort } = getEstimateStats();
  const {
    lowCost,
    highCost,
    averageCost,
    averageRevenue,
    averageMargin,
    lowMargin,
    highMargin,
  } = calculateCost();

  if (minimal) {
    return (
      <Grid container>
        <Grid item direction="row" className={classes.effortItem}>
          <Typography variant="body2">High Estimate</Typography>
          <Typography variant="subtitle2">${formatMoney(highCost)}<br/>{highEffort}hrs</Typography>
        </Grid>
        <Grid item direction="row" className={classes.effortItem}>
          <Typography variant="body2">Low Estimate</Typography>
          <Typography variant="subtitle2">${formatMoney(lowCost)}<br/>{lowEffort}hrs</Typography>
        </Grid>
        <Grid item direction="row" className={classes.effortItem}>
          <Typography variant="body2">Margin</Typography>
          <Typography variant="subtitle2">{percFormat(lowMargin)}% - {percFormat(highMargin)}% </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item direction="row" className={classes.effortItem}>
        <Typography variant="body2">Low Effort</Typography>
        <Typography variant="subtitle2">{lowEffort}</Typography>
      </Grid>
      <Grid item className={classes.effortItem}>
        <Typography variant="body2">High Effort</Typography>
        <Typography variant="subtitle2">{highEffort}</Typography>
      </Grid>
      <Grid item className={classes.effortItem}>
        <Typography variant="body2">Average Effort</Typography>
        <Typography variant="subtitle2">{averageEffort}</Typography>
      </Grid>
      <Grid item direction="row" className={classes.effortItem}>
        <Typography variant="body2">Low Cost</Typography>
        <Typography variant="subtitle2">${formatMoney(lowCost)}</Typography>
      </Grid>
      <Grid item className={classes.effortItem}>
        <Typography variant="body2">High Cost</Typography>
        <Typography variant="subtitle2">${formatMoney(highCost)}</Typography>
      </Grid>
      <Grid item className={classes.effortItem}>
        <Typography variant="body2">Average Cost</Typography>
        <Typography variant="subtitle2">${formatMoney(averageCost)}</Typography>
      </Grid>
      <Grid item className={classes.effortItem}>
        <Typography variant="body2">Average Revenue</Typography>
        <Typography variant="subtitle2">
          ${formatMoney(averageRevenue)}
        </Typography>
      </Grid>
      <Grid item className={classes.effortItem}>
        <Typography variant="body2">Average Margin</Typography>
        <Typography variant="subtitle2">
          {percFormat(averageMargin)}%
        </Typography>
      </Grid>
    </Grid>
  );
};

export { EstimateMetrics as EstimateMetricsVanlla };
export default EstimateMetricsComponent;
