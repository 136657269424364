module.exports = [
  { domain: 'Technology', role: 'Junior Developer', rate: 100 },
  { domain: 'Technology', role: 'Associate Developer', rate: 150 },
  { domain: 'Technology', role: 'Senior Developer', rate: 175 },
  { domain: 'Technology', role: 'Architect', rate: 200 },
  { domain: 'Technology', role: 'Senior Architect', rate: 225 },
  { domain: 'Visual Design', role: 'Junior Designer', rate: 100 },
  { domain: 'Visual Design', role: 'Designer', rate: 125 },
  { domain: 'Visual Design', role: 'Senior Designer', rate: 175 },
  { domain: 'Visual Design', role: 'Art Director', rate: 200 },
  { domain: 'Visual Design', role: 'Creative Director', rate: 225 },
  { domain: 'User Experience', role: 'Junior UX', rate: 100 },
  { domain: 'User Experience', role: 'UX', rate: 125 },
  { domain: 'User Experience', role: 'Senior UX', rate: 150 },
  { domain: 'User Experience', role: 'Art Director', rate: 175 },
  { domain: 'User Experience', role: 'Creative Director', rate: 225 },
  { domain: 'Strategy', role: 'Junior Strategist', rate: 100 },
  { domain: 'Strategy', role: 'Strategist', rate: 150 },
  { domain: 'Strategy', role: 'Senior Strategist', rate: 175 },
  { domain: 'Strategy', role: 'Strategy Lead', rate: 200 },
  { domain: 'Strategy', role: 'Chief Strategist', rate: 225 },
  {
    domain: 'Project Management',
    role: 'Junior Project Manager',
    rate: 100,
  },
  { domain: 'Project Management', role: 'Project Manager', rate: 150 },
  {
    domain: 'Project Management',
    role: 'Senior Project Manager',
    rate: 175,
  },
  { domain: 'Project Management', role: 'Delivery Lead', rate: 100 },

  {
    domain: 'Account Management',
    role: 'Junior Account Specialist',
    rate: 125,
  },
  { domain: 'Account Management', role: 'Account Manager', rate: 125 },
  {
    domain: 'Account Management',
    role: 'Senior Account Manager',
    rate: 175,
  },
  { domain: 'Account Management', role: 'Client Partner', rate: 225 },
];
