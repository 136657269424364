export const colors = {
  primary: {
    light: '#575b64',
    main: '#2e323a',
    dark: '#1F2633',
  },
  secondary: {
    light: '#6b9fc4',
    main: '#4787b6',
    dark: '#315e7f',
  },
  grey: {
    light: '#f5f7fb',
    main: '#edeef5',
    dark: '#9da4bf',
  },
  accent: {
    green: '#52c14e',
    red: '#f75177',
    purple: '#4a2b8c',
  },
}

export const palette = {
  background: {
    default: colors.grey.main
  },
  primary: {
    light: colors.primary.light,
    main: colors.primary.main,
    dark: colors.primary.dark,
    contrastText: colors.grey.light,
  },
  secondary: {
    light: colors.secondary.light,
    main: colors.secondary.main,
    dark: colors.secondary.dark,
    contrastText: colors.grey.light,
  },
  greyLight: {
    main: colors.grey.light,
    contrastText: colors.primary.main,
  },
  grey: {
    main: colors.grey.main,
    contrastText: colors.primary.main,
  },
  greyDark: {
    main: colors.grey.dark,
    contrastText: colors.grey.light,
  },
  accentGreen: {
    main: colors.accent.green,
    contrastText: colors.grey.light,
  },
  accentRed: {
    main: colors.accent.red,
    contrastText: colors.grey.light,
  },
  accentPurple: {
    main: colors.accent.purple,
    contrastText: colors.grey.light,
  },
}
