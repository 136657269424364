import React, { useEffect } from 'react';
import { styled } from '@material-ui/styles';
import { Box } from '@material-ui/core';

import { useRanglrContext } from 'context/provider.ranglr';

const backgroundImage = `url("images/splash/1.jpg")`;

const SplashBox = styled(Box)({
  backgroundImage,
  backgroundSize: 'cover',
  position: 'absolute', 
  top: '0',
  left: '0', 
  width: '100%', 
  height: '100%',
});

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const HomeComponent = () => {
  const [, ranglrDispatch] = useRanglrContext();
  useEffect(() => {
    ranglrDispatch({ type: 'SET_BREADCRUMB', payload: { currentPage: 'Home' } });
  }, [ranglrDispatch]);

  return (
    <SplashBox />
  )
};

export default HomeComponent;
