
import { styled, makeStyles } from '@material-ui/core/styles';

import { Box} from '@material-ui/core';

export const CustomStoriesList = styled(Box)({

});


export const useStyles = makeStyles(({ spacing }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    backgroundColor: 'white',
    padding: 30,
  },
  main: {
    padding: spacing(3),
  },
  button: {
    margin: `0 ${spacing(3)}px`
  }
}));
