import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    backgroundColor: 'white',
    padding: 30,
  },
  main: {
    padding: spacing(3),
  },
  drawer: {
    [breakpoints.up('sm')]: {
      width: '70vw',
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: '80vw',
    background: 'white',
  },
}));
