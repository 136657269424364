import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  const { transitions, palette } = theme;

  return {
    profileWrapper: {
      padding: '3em 0 3em 0',
      transition: transitions.create(['padding'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    profileWrapperClosed: {
      padding: '2em 0 1em 0',
    },
    icon: {
      fontSize: 35,
    },
    imageIcon: {
      width: 40,
      height: 40,
      border: `1px solid ${palette.primary.light}`,
      transition: transitions.create(['width', 'height'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    bigImageIcon: {
      width: 80,
      height: 80,
      border: `1px solid ${palette.primary.light}`,
    },
    imageWrapper: {
      margin: '0 auto',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    name: {
      color: palette.primary.contrastText,
      padding: '0 1em',
    },
  };
});

export default useStyles;
