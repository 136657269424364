import { styled, makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

export const Permissions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const useStyles = makeStyles(theme => ({
  redPill: {
    background: theme.palette.accentRed.main,
    color: 'white',
  },
  toolbar: {
    background: theme.palette.grey.main,
  },
}));
