import React, { useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Typography, Chip, Box } from '@material-ui/core';
import { chips } from 'config/roles';

import { GET_STORIES } from 'queries';
import { useRanglrContext } from 'context/provider.ranglr';
import FilterHeader from 'components/molecules/FilterHeader';
import Filters from 'components/molecules/Filters';
import Table from 'components/molecules/Table';
import CreateStoryButton from 'components/molecules/CreateStoryButton';
import StoryUploadButton from 'components/molecules/StoryUploadButton';
import CustomStoriesList from 'components/molecules/CustomStoriesList';

import { useStyles } from './StoryManagement.style';

const reducer = (state, action) => {
  switch (action.type) {
  case 'SET':
    return { ...state, ...action.payload };
  case 'CLOSE_MODAL':
    return { ...state, activeStory: null };
  case 'UPDATE_SET_FILTER':
    if (action.payload.isActive) {
      const activeFilters = [...state.activeFilters];
      activeFilters.splice(activeFilters.indexOf(action.payload.filter), 1);
      return { ...state, activeFilters };
    }
    return {
      ...state,
      activeFilters: [...state.activeFilters].concat([action.payload.filter]),
    };
  default:
    throw new Error();
  }
};

const defaultState = {
  storiesState: [],
  activeFilters: [],
  activeStory: null,
  sorting: null,
  filters: null,
};

const buster = Date.now();

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const StoryManagementComponent = () => {
  const [, ranglrDispatch] = useRanglrContext();
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, defaultState);
  const { storiesState, storyTags = [], activeFilters, activeStory } = state;

  // Graph QL
  const { data: { getStories: stories = [] } = {} } = useQuery(GET_STORIES, {
    variables: { buster },
  });

  useEffect(() => {
    ranglrDispatch({
      type: 'SET_BREADCRUMB',
      payload: { currentPage: 'Stories' },
    });
  }, [ranglrDispatch]);

  useEffect(() => {
    const storiesStateVar = stories.filter(story => {
      let show = false;
      const { tasks } = story;
      if (tasks && typeof tasks === 'string' && activeFilters.length > 0) {
        const taskObject = JSON.parse(tasks);
        taskObject.forEach(task => {
          if (activeFilters.includes(task.domain)) {
            show = true;
          }
        });
      } else {
        show = true;
      }
      return show;
    });
    dispatch({ type: 'SET', payload: { storiesState: storiesStateVar } });
  }, [activeFilters, stories]);

  useEffect(() => {
    if (stories && stories.length > 0) {
      const allTags = [];

      stories.forEach(story => {
        const { tasks } = story;
        if (tasks && typeof tasks === 'string') {
          const taskObject = JSON.parse(tasks);
          taskObject.forEach(task => {
            if (allTags.indexOf(task.domain) === -1) {
              allTags.push(task.domain);
            }
          });
        }
      });
      dispatch({
        type: 'SET',
        payload: { storiesState: stories, storyTags: allTags },
      });
    }
  }, [stories]);

  const renderStories = (incomingStories, newTitle) => {
    const storiesWithTags = incomingStories.map(story => {
      const out = { ...story };
      const { tasks } = story;
      const tags = [];
      if (tasks && typeof tasks === 'string') {
        const taskObject = JSON.parse(tasks);
        taskObject.forEach(task => {
          if (tags.indexOf(task.domain) === -1) {
            tags.push(task.domain);
          }
        });
      }
      out.tags = tags;
      return out;
    });

    return (
      <Table
        columns={[
          { title: 'Title', field: 'title' },
          { title: 'Description', field: 'description' },
          { title: 'High Effort', field: 'high' },
          { title: 'Low Effort', field: 'low' },
          {
            field: 'tags',
            title: 'Tags',
            render: rowData =>
              rowData.tags.map(tag => {
                if (tag && chips[tag])
                  return (
                    <Chip
                      label={chips[tag].id}
                      size="small"
                      className={`${classes.chip} ${chips[tag].id}`}
                      key={chips[tag].id}
                    />
                  );
                return null;
              }),
          },
        ]}
        onRowClick={(event, storyObj) => {
          dispatch({ type: 'SET', payload: { activeStory: storyObj } });
        }}
        data={storiesWithTags}
        title={newTitle}
      />
    );
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h1">Story Management</Typography>
        <Box>
          <CreateStoryButton activeStory={activeStory} dispatch={dispatch} />
          <StoryUploadButton />
        </Box>
      </div>
      {storiesState && storiesState.length > 0 && (
        <div className={classes.main}>
          <FilterHeader>
            <Typography variant="body2">Filter By Domain</Typography>
            <Filters
              activeFilters={activeFilters}
              pillFilters={storyTags}
              dispatch={dispatch}
              filterDispatchType="UPDATE_SET_FILTER"
            />
          </FilterHeader>
          {renderStories(storiesState, 'Global Stories')}
        </div>
      )}
      <CustomStoriesList />
      
    </>
  );
};

export default React.memo(StoryManagementComponent);
