const initialState = {
  // OLD
  activeStory: null,
  calendarWidth: 12,
  categorySkills: [],
  collaborators: null,
  currentStorySelection: [],
  currentTaskAcceptingGroups: [],
  estimate: [],
  estimateId: null,
  isCollaboratorDrawerOpen: false,
  project_brief: null,
  showSnackbarMessage: false,
  snackbarMessage: '',
  snackbarVariant: '',
  startDate: null,
  title: null,
  totals: null,
  // NEW
  opportunityId: null,
  harvestId: null,
  accountId: null,
  currentOpportunity: null,
  currentEstimateId: null,
  activeGroup: null,
  storyModalOpen: false,
  rateCard: [],
};

const setTaskEditingGroups = (currentTaskAcceptingGroups, payload) => {
  const tempCurrentTaskAcceptingGroups = [];
  let isInArray = false;
  currentTaskAcceptingGroups.forEach(({ id, isChecked }) => {
    if (id === payload.id) {
      isInArray = true;
      tempCurrentTaskAcceptingGroups.push({ id, isChecked: payload.isChecked });
    } else {
      tempCurrentTaskAcceptingGroups.push({ id, isChecked });
    }
  });
  if (!isInArray)
    tempCurrentTaskAcceptingGroups.push({
      id: payload.id,
      isChecked: payload.isChecked,
    });
  return tempCurrentTaskAcceptingGroups;
};

const updateGroup = (estimate, newGroup) =>
  estimate.map(group => {
    if (group.id === newGroup.id) {
      return { ...group, ...newGroup };
    }
    return group;
  });

const reducer = (state, action) => {
  switch (action.type) {
  case 'ADD_GROUP_TO_ESTIMATE':
  case 'CHANGE_CURRENT_GROUP_NAME':
  case 'OPEN_COLLABORATOR_DRAWER':
  case 'SET_ACCOUNT_ID':
  case 'SET_ACTIVE_STORY':
  case 'SET_CURRENT_ESTIMATE_TITLE':
  case 'SET_CURRENT_ESTIMATE_ID':
  case 'SET_CURRENT_ESTIMATE_HARVEST_ACCOUNT_ID':
  case 'SET_CURRENT_STORY_LIST':
  case 'SET_OPEN_DIALOG':
  case 'SET_OPPORTUNITY_ID':
  case 'SET_SKILLS_ON_CURRENT_STORY':
  case 'SET_STAFFING_ESTIMATE':
  case 'SET_STORIES_TO_ADD_TO_GROUPS':
  case 'SHOW_SNACKBAR_MESSAGE':
  case 'INIT_OPPORTUNITY':
  case 'SET':
    return { ...state, ...action.payload };
  case 'UPDATE_TASK_EDITING':
    return {
      ...state,
      ...{
        currentTaskAcceptingGroups: setTaskEditingGroups(
          state.currentTaskAcceptingGroups,
          action.payload,
        ),
      },
      calendarWidth:
        setTaskEditingGroups(state.currentTaskAcceptingGroups, action.payload)
          .length > 0
          ? 8
          : 12,
    };
  case 'SAVE_CURRENT_GROUP_NAME':
    return {
      ...state,
      estimate: updateGroup(state.estimate, action.payload.group),
    };
  case 'SHOW_STORY_MODAL':
    return { ...state, ...action.payload, storyModalOpen: true };
  case 'CLOSE_STORY_MODAL':
    return {
      ...state,
      ...action.payload,
      activeGroup: null,
      storyModalOpen: false,
    };
  default:
    throw new Error(
      `Unhandled action type in estimate reducer for: ${action.type}`,
    );
  }
};

export { reducer, initialState };
