import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from '@reach/router';

import { useStyles } from './Breadcrumb.style';

const crumbExample = [
  {
    title: 'Home',
    link: '/',
  },
];

const Breadcrumb = ({ currentPage = 'Current Page', crumbs = crumbExample }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="Breadcrumb">
        {crumbs.map(crumb => (
          <Link key={crumb.title} color="inherit" to={crumb.link}>
            <Typography color="textPrimary" variant="body2">{crumb.title}</Typography>
          </Link>
        ))}
        <Typography color="textPrimary" variant="body2">{currentPage}</Typography>
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
