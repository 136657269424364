import { colors } from '../colors';

export default {
  root: {
    backgroundColor: 'white',
    borderTop: `2px solid ${colors.grey.main}`,
    borderBottom: `2px solid ${colors.grey.main}`,
  },
  head: {
    borderTop: 'none',
    borderBottom: `6px solid ${colors.grey.main}`,
  },
  footer: {
    backgroundColor: colors.grey.light,
    borderTop: `4px solid ${colors.grey.main}`,
    borderBottom: 'none',
  },
}
