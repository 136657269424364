import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(({ palette, spacing, shadows }) => ({
  paper: {
    position: 'absolute',
    width: '90vw',
    backgroundColor: palette.grey.main,
    boxShadow: shadows[5],
    padding: 0,
    outline: 'none',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: palette.background.paper,
    padding: spacing(2),
  },
  main: {
    paddingRight: spacing(3),
    paddingBottom: spacing(3),
    paddingLeft: spacing(3),
  },
}));

const ModalComponent = ({ children, isOpen, handleClose, title = 'Set the title', noPadding }) => {
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h3">{title}</Typography>
          <IconButton onClick={handleClose} aria-label="Close">
            <Close />
          </IconButton>
        </div>
        <div className={`${!noPadding && classes.main}`}>{children}</div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
