import React from 'react';
import { Router } from '@reach/router';
import ProtectedRoute from 'components/templates/ProtectedRoute';
import Accounts from 'components/templates/Accounts';
import Estimations from 'components/templates/Estimations';
import Home from 'components/templates/Home';
import OpportunityDetail from 'components/templates/OpportunityDetail';

import StoryManagement from 'components/templates/StoryManagement';
import EstimateAccepted from 'components/templates/EstimateAccepted';
import TagManagement from 'components/templates/TagManagement';
import Permissions from 'components/templates/Permissions';
import Opportunities from 'components/templates/Opportunities';
import RateCardManagement from 'components/templates/RateCardManagement';

const PublicRoutes = ({ history }) => (
  <Router history={history}>
    <ProtectedRoute render={<Accounts />} path="/accounts" />
    <ProtectedRoute render={<Accounts />} path="/accounts/:accountId" />
    <ProtectedRoute render={<OpportunityDetail />} path="/accounts/:accountId/:opportunityId" />
    <ProtectedRoute render={<EstimateAccepted />} path="/estimate-accepted/:estimateId" />
    <ProtectedRoute render={<Estimations />} path="/estimations" />
    <ProtectedRoute render={<StoryManagement />} path="/stories" />
    <ProtectedRoute render={<TagManagement />} path="/tags" />
    <ProtectedRoute render={<Home />} path="/" />
    <ProtectedRoute allowedRoles="isLeadership" render={<Permissions />} path="/permissions" />
    <ProtectedRoute render={<Opportunities />} path="/opportunities" />
    <ProtectedRoute render={<RateCardManagement />} path="/rates" />
  </Router>
);

export default PublicRoutes;
