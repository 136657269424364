import gql from 'graphql-tag';

const GET_TAGS = gql`
  query getTagsQuery {
    getTag {
      id
      label
      parentId
      type
    }
  }
`;

const UPDATE_TAG = gql`
  mutation updateTagQuery(
    $id: ID
    $parentId: ID
    $label: String
    $type: String
  ) {
    updateTag(
      id: $id
      parentId: $parentId
      label: $label
      type: $type
    ) {
      id
      parentId
      label
      type
    }
  }
`;

const DELETE_TAG = gql`
  mutation deleteTagQuery(
    $id: ID
  ) {
    deleteTag(
      id: $id
    )
  }
`;

const GET_TAG_MAPPINGS = gql`
  query getTagMappingsQuery {
    getTagMapping {
      id
      entityId
      tagId
      level
      type
    }
  }
`;

const CREATE_TAG_MAPPING = gql`
  mutation createTagMappingQuery(
    $id: ID
    $entityId: ID
    $tagId: ID
    $level: Int
    $type: String
  ) {
    updateTagMapping(
      id: $id
      entityId: $entityId
      tagId: $tagId
      level: $level
      type: $type
    ) {
      id
      entityId
      tagId
      level
      type
    }
  }
`;

const DELETE_TAG_MAPPING = gql`
  mutation deleteTagMappingQuery($id: ID!, $entityId: ID!) {
    deleteTagMapping(id: $id, entityId: $entityId)
  }
`;

export { GET_TAGS, UPDATE_TAG, DELETE_TAG, GET_TAG_MAPPINGS, CREATE_TAG_MAPPING, DELETE_TAG_MAPPING };
