import gql from 'graphql-tag';

const EMPLOYEE_DETAILS = `
  primaryEmail
  id
  thumbnailPhotoUrl
  photoData
  name {
    givenName
    familyName
  }
  organizations {
    title
    department
    description
  }
`;

const CLOUD_DIRECTORY = `  
  id
  name
  createdTime
  documentData{
    kind
    id
    name
    mimeType
    teamDriveId
    driveId
  }
  capabilities{
    canAddChildren
    canChangeCopyRequiresWriterPermissionRestriction
    canChangeDomainUsersOnlyRestriction
    canChangeDriveBackground
    canChangeDriveMembersOnlyRestriction
    canComment
    canCopy
    canDeleteChildren
    canDeleteDrive
    canDownload
    canEdit
    canListChildren
    canManageMembers
    canReadRevisions
    canRename
    canRenameDrive
    canShare
    canTrashChildren
  }
  restrictions {
    adminManagedRestrictions
    copyRequiresWriterPermission
    domainUsersOnly
    driveMembersOnly
  }
`;

const GET_GSUITE_EMPLOYEES = gql`
  query getGsuiteEmployees {
    getGSuiteEmployees {
      ${EMPLOYEE_DETAILS}
    }
  }
`;

const GET_GSUITE_EMPLOYEE = gql`
  query getGsuiteEmployee($userKey: String!) {
    getGSuiteEmployee(userKey: $userKey) {
      ${EMPLOYEE_DETAILS}
    }
  }
`;



const CREATE_CLOUD_DIRECTORY = gql`
  mutation createCloudDirectory(
    $accountId:ID!, 
    $opportunityId:String!, 
    $accountName:String!,
    $title:String!, 
    $requestId:String) {
      createCloudDirectory(
        accountId:$accountId, 
        opportunityId:$opportunityId, 
        accountName:$accountName, 
        title:$title, 
        requestId:$requestId) {
        ${CLOUD_DIRECTORY}
      }
  }
`;

export { GET_GSUITE_EMPLOYEES, GET_GSUITE_EMPLOYEE, CREATE_CLOUD_DIRECTORY };
// export { GET_GSUITE_EMPLOYEES, GET_GSUITE_EMPLOYEE};
