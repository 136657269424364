import React from 'react';

import { Estimate } from './Estimate.style';
import EstimateSummary from '../EstimateSummary';
import EstimateConfigurator from '../EstimateConfigurator';

const EstimateComponent = () => (
  <Estimate>
    <EstimateSummary />
    <EstimateConfigurator />
  </Estimate>
);

export { Estimate as EstimateVanlla };
export default EstimateComponent;
