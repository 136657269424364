import React from 'react';
import { Router } from '@reach/router';

import Home from 'components/templates/Home';

const PublicRoutes = ({ history }) => (
  <Router history={history}>
    <Home path="/" />
    <Home path="/signout" />
    <Home path="/success" />
  </Router>
);

export default PublicRoutes;
