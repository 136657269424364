import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: 30,
    margin: spacing(3),
    marginBottom: 0,
    marginTop: 0,
  },
  logo: {
    maxHeight: 80,
  },
  main: {
    flexGrow: 1,
    padding: spacing(3),
  },
  sidebarWrapper: {
    backgroundColor: palette.greyLight.main,
    border: `1px solid ${palette.greyDark.main}`,
    padding: spacing(2),
  },
  selectGrid: {
    textAlign: "right",
  }
}));
