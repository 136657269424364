import { styled } from '@material-ui/core/styles';
import { Chip, Box } from '@material-ui/core';

export const PillWrapper = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(1)}px 0`,
}));

export const Pill = styled(Chip)(({ theme, colorvalue, active }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette[colorvalue].main}`,
  backgroundColor: `${active > 0 ? theme.palette[colorvalue].main : 'white'}`,
  color: `${active ? 'white' : theme.palette[colorvalue].main}`,
  '&:hover': {
    backgroundColor: `${active > 0 ? theme.palette[colorvalue].main : 'white'}`,
  },
  '&:focus': {
    backgroundColor: `${active > 0 ? theme.palette[colorvalue].main : 'white'}`,
  }
}));
