import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Container,
  Typography,
  Paper,
  Grid,
} from '@material-ui/core';
import { useEstimateContext, useRanglrContext } from 'context/provider.ranglr';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import {
  CREATE_PROJECT,
  CREATE_CLOUD_DIRECTORY,
  GET_OPPORTUNITY_DETAILS,
} from 'queries';
import { getParsedObject } from 'utils/formatters';
import useEstimateUtils from 'utils/useEstimateUtils';
import Loading from 'components/atoms/Loading';
import EstimationStepper from '../EstimateStepper';

import GroupAccordion from './GroupAccordion';
import { useStyles, EstimationPropagation } from './EstimatePropogation.style';
import EstimateMetrics from '../EstimateMetrics';

const EstimatePropogationComponent = ({ estimateId }) => {
  const classes = useStyles();
  const [, ranglrDispatch] = useRanglrContext();

  const [, setParsedEstimate] = useState({});
  // const [, setIsApproved] = useState(false);
  const [completed, setCompleted] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [estimateContext, dispatchEstimateContext] = useEstimateContext();
  const { harvestId, currentEstimate = {} } = estimateContext;

  // console.log("TCL: EstimatePropogationComponent -> estimateContext", estimateContext)
  const groups = getParsedObject(currentEstimate, 'groups', []);
  const cloudDirectory = getParsedObject(currentEstimate, 'cloudDirectory', {});
  const harvestProject = getParsedObject(currentEstimate, 'harvestProject', {});

  const { title, opportunityId, accountId } = currentEstimate;

  const vars = {
    clientId: harvestId,
    name: `${title}`,
    isBillable: true,
    billBy: 'none',
    budgetBy: 'none',
  };

  const {
    setCloudDirectory,
    setHarvestProject,
    setActiveEstimate,
    setEstimateApproval,
  } = useEstimateUtils();

  const [
    createProject,
    { data: createProjectSuccess, error: queryError },
  ] = useMutation(CREATE_PROJECT, {
    variables: vars,
  });

  const [
    createCloudDirectory,
    { data: createCloudDirectorySuccess },
  ] = useMutation(CREATE_CLOUD_DIRECTORY, {
    variables: {
      accountId,
      opportunityId,
      accountName: title,
      title,
      requestId: null,
    },
  });

  const [
    getOpportunityDetails,
    { data: opportunityDetailsData = {} },
  ] = useLazyQuery(GET_OPPORTUNITY_DETAILS);

  useEffect(() => {
    if (accountId && opportunityId) {
      getOpportunityDetails({ variables: { accountId, opportunityId } });
      ranglrDispatch({
        type: 'SET_BREADCRUMB',
        payload: { currentPage: 'Estimate Summary', crumbs: [{
          link: `/accounts/${accountId}/${opportunityId}`,
          title,
        }] },
      });
    }
  }, [accountId, opportunityId]);

  useEffect(() => {
    const { getOpportunity } = opportunityDetailsData;
    if (getOpportunity && getOpportunity.length > 0) {
      const opp = getOpportunity[0];
      dispatchEstimateContext({
        type: 'SET_CURRENT_ESTIMATE_HARVEST_ACCOUNT_ID',
        payload: { harvestId: opp.Account.Harvest_ID__c },
      });
    }
  }, [opportunityDetailsData]);

  const setNewCompleted = step => {
    const newCompleted = completed;
    newCompleted[step] = true;

    setCompleted(newCompleted);
  };

  useEffect(() => {
    if (queryError) {
      const currentError = `Please make sure that there is a Harvest ID in this 
        Salesforce account or that this estimate hasn't already been created within Harves`;

      ranglrDispatch({
        type: 'SET_MESSAGE',
        payload: {
          variant: 'error',
          showMessage: true,
          message: currentError,
        },
      });
    }
  }, [queryError]);

  const updateStep = step => {
    setActiveStep(step);

    if (step === 0) {
      setShowLoading(true);
      createProject();
    }

    if (step === 1) {
      setShowLoading(true);
      createCloudDirectory();
    }

    if (step === 2) {
      setShowLoading(true);
      setEstimateApproval({ isApprovedBool: true, opportunityId, accountId });
    }
  };

  useEffect(() => {
    if (createProjectSuccess) {
      const { createProject: createProjectData } = createProjectSuccess;
      if (createProjectData) {
        setHarvestProject({ opportunityId, accountId, createProjectData });

        const parsedGroups = groups.map(group =>
          getParsedObject(group, 'stories', []),
        );
        const tempParsedEstimate = {
          ...currentEstimate,
          groups: parsedGroups[0],
        };
        setParsedEstimate(tempParsedEstimate);
        setShowLoading(false);
      }
    }
  }, [createProjectSuccess]);

  useEffect(() => {
    if (createCloudDirectorySuccess) {
      const {
        createCloudDirectory: cloudDirectoryData,
      } = createCloudDirectorySuccess;

      if (cloudDirectoryData) {
        setCloudDirectory({
          cloudDirectoryData: createCloudDirectorySuccess.createCloudDirectory,
          opportunityId,
          accountId,
        });
        setShowLoading(false);
      }
    }
  }, [createCloudDirectorySuccess]);

  useEffect(() => {
    const { isApproved: isApprovedBool } = currentEstimate;

    if (isApprovedBool) {
      setNewCompleted(2);
      setShowLoading(false);
    }
  }, [currentEstimate]);

  useEffect(() => {
    if (cloudDirectory && cloudDirectory.name) setNewCompleted(1);
    setShowLoading(false);
  }, [cloudDirectory]);

  useEffect(() => {
    if (harvestProject && harvestProject.name) setNewCompleted(0);
    setShowLoading(false);
  }, [harvestProject]);

  useEffect(() => {
    if (estimateId) setActiveEstimate(estimateId);
  }, [estimateId]);

  const ListItemLink = props => <ListItem button component="a" {...props} />;

  if (!estimateId) return null;
  return (
    <>
      {showLoading && <Loading />}
      <EstimationPropagation className={classes.root}>
        <Container>
          <Paper className={classes.paper}>
            <Typography variant="h1">{title}</Typography>
            <br />
            <EstimateMetrics minimal />
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h1">Next Steps</Typography>
            <EstimationStepper
              activeStep={activeStep}
              updateStep={updateStep}
              completed={completed}
            />
          </Paper>

          <Paper className={classes.paper}>
            <Typography variant="h1">Resource Links</Typography>
            <List>
              {cloudDirectory && cloudDirectory.name && (
                <ListItem>
                  <ListItemText
                    disableTypography
                    classes={{ root: classes.listItemText }}
                    className={classes.listItemText}
                  >
                    Google Drive Name :
                  </ListItemText>

                  <ListItemLink
                    href={`https://drive.google.com/drive/u/0/folders/${cloudDirectory.id}`}
                    target="_blank"
                  >
                    <ListItemText
                      disableTypography
                      primary={cloudDirectory.name}
                    />
                  </ListItemLink>
                </ListItem>
              )}
              {harvestProject && harvestProject.name && (
                <ListItem>
                  <ListItemText
                    disableTypography
                    classes={{ root: classes.listItemText }}
                    className={classes.listItemText}
                  >
                    Harvest Project Link :
                  </ListItemText>
                  <ListItemLink
                    href={`https://goodsservices.harvestapp.com/projects/${harvestProject.id}`}
                    target="_blank"
                  >
                    <ListItemText
                      disableTypography
                      primary={harvestProject.name}
                    />
                  </ListItemLink>
                </ListItem>
              )}
            </List>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h1">Stories and Tasks</Typography>
            <Grid container className={classes.tableHead}>
              <Grid
                container
                xs={1}
                alignItems="center"
                justify="center"
              ></Grid>
              <Grid container xs={3}>
                Group
              </Grid>
              <Grid container xs={2}>
                High Estimate
              </Grid>
              <Grid container xs={2}>
                Low Estimate
              </Grid>
            </Grid>
            {groups && groups.map(g => <GroupAccordion group={g} />)}
          </Paper>
        </Container>
      </EstimationPropagation>
    </>
  );
};

export { EstimatePropogationComponent as EstimateAcceptedVanlla };
export default EstimatePropogationComponent;
