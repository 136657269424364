import React from 'react';
import clsx from 'clsx';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';
import {
  BarChart,
  Assignment,
  AspectRatio,
  Lock,
  Label,
  Money,
  AttachMoney,
} from '@material-ui/icons';
import { navigate, Location } from '@reach/router';

import { useAuthContext } from 'context/provider.ranglr';
import Profile from 'components/molecules/Profile';
import { useFlag } from 'components/organisms/Flags';
import Styles from './NavigationDrawer.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const NavigationDrawerComponent = ({ open }) => {
  const [authState] = useAuthContext();
  const { authenticated, permissions } = authState;
  const isLeadership = useFlag(['isLeadership']);
  const classes = Styles();
  const getMenuItems = pathname => [
    {
      title: 'Accounts',
      link: '/accounts',
      icon: <BarChart className={classes.icon} />,
      active: pathname.indexOf('/accounts') > -1,
      canSee: true,
    },
    {
      title: 'Opportunities',
      link: '/opportunities',
      icon: <Money className={classes.icon} />,
      active: pathname.indexOf('/opportunities') > -1,
      canSee: true,
    },
    {
      title: 'Estimations',
      link: '/estimations',
      icon: <AspectRatio className={classes.icon} />,
      active: pathname.indexOf('/estimations') > -1,
      canSee: true,
    },
    {
      title: 'Stories',
      link: '/stories',
      icon: <Assignment className={classes.icon} />,
      active: pathname.indexOf('/stories') > -1,
      canSee: true,
    },
    {
      title: 'Permissions',
      link: '/permissions',
      icon: <Lock className={classes.icon} />,
      active: pathname.indexOf('/permissions') > -1,
      canSee: isLeadership,
    },
    {
      title: 'Taxonomy',
      link: '/tags',
      icon: <Label className={classes.icon} />,
      active: pathname.indexOf('/tags') > -1,
      canSee: isLeadership,
    },
    {
      title: 'Rate Cards',
      link: '/rates',
      icon: <AttachMoney className={classes.icon} />,
      active: pathname.indexOf('/rates') > -1,
      canSee: isLeadership,
    },
  ];

  const renderDrawer = (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <Profile iconClass={classes.icon} open={open} />
      <Location>
        {({ location: { pathname } }) => {
          const menuItems = getMenuItems(pathname);
          return (
            <List className={classes.list}>
              {menuItems.map(
                item =>
                  item.canSee && (
                    <Tooltip title={item.title} placement="right" disableHoverListener={open} key={item.title}>
                      <ListItem
                        className={clsx(classes.listItem, {
                          [classes.listItemActive]: item.active && open,
                          [classes.listItemActiveClosed]: item.active && !open,
                        })}
                        button
                        onClick={() => {
                          navigate(item.link);
                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText className={classes.listItemText} primary={item.title} />
                        {open && item.active && <div className={classes.listItemHighlight} />}
                      </ListItem>
                    </Tooltip>
                  ),
              )}
            </List>
          );
        }}
      </Location>
    </Drawer>
  );

  return <div className={classes.root}>{authenticated && permissions && renderDrawer}</div>;
};

export default NavigationDrawerComponent;
