import React from 'react';
import { styled, useTheme } from '@material-ui/styles';
import { Box, Button } from '@material-ui/core';

import { useAuthContext } from 'context/provider.ranglr';
import {
  GradientContainer,
  LoginH1,
  LoginH2,
  LoginTextContainer,
} from './Login.style';

const backgroundImage = `url("images/splash/1.jpg")`;

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const LoginComponent = () => {
  const [authState] = useAuthContext();
  const { signIn } = authState;
  const theme = useTheme();
  const { transitions, palette, breakpoints } = theme;

  const toolbarHeights = {
    med: 56,
    xs: 48,
    sm: 64,
  };

  const drawerWidth = 240;

  const SplashBox = styled(Box)({
    backgroundImage,
    backgroundSize: 'cover',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  });

  const LeftRail = styled(Box)({
    width: '240px',
    backgroundColor: palette.primary.main,
    position: 'absolute',
    height: '100%',
    zIndex: '10',
    top: 0,
    left: 0,
    textAlign: 'center',
  });

  const LogoWrapper = styled(Box)({
    backgroundColor: palette.secondary.dark,
    position: 'absolute',
    zIndex: 9999,
    top: 0,
    left: 0,
    width: drawerWidth,
    height: toolbarHeights.med + 1,
    [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
      height: toolbarHeights.xs + 1,
    },
    [breakpoints.up('sm')]: {
      height: toolbarHeights.sm + 1,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  });

  const ButtonWrapper = styled(Box)({
    marginTop: 110,
  });

  const Logo = styled(Box)({
    fontSize: 30,
    fontWeight: 300,
    fontFamily: 'Quicksand',
    color: palette.grey.main,
  });

  return (
    <>
      <LeftRail>
        <LogoWrapper>
          <Logo>ranglr</Logo>
        </LogoWrapper>
        <ButtonWrapper>
          <Button type="button" onClick={signIn}>
            Sign in
          </Button>
        </ButtonWrapper>
      </LeftRail>
      <SplashBox>
        <GradientContainer>
          <LoginTextContainer>
            <LoginH1>Well HOT DAMN!!!</LoginH1>
            <LoginH2>
              Round up all your sales, people and project management tools into
              one view and management system.
            </LoginH2>
          </LoginTextContainer>
        </GradientContainer>
      </SplashBox>
    </>
  );
};

export default LoginComponent;
