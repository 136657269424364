import gql from 'graphql-tag';

const GET_TIMECARDS_BY_GROUP = gql`
  query getTimeCardsByGroupQuery($from: String!, $to: String!, $group: String!, $groupId: Int!) {
    getTimeCardsByGroup(from: $from, to: $to, group: $group, groupId: $groupId) {
      hours
      clientName
      userName
      projectName
    }
  }
`;

const getTimeCardsWithRange = ({ alias, from, to }) => `
  ${alias}: getTimeCardsByDate(from: "${from}", to: "${to}") {
    id
    entryId
    date
    hours
    userId
    userName
    clientId
    clientName
    projectId
    projectName
    taskId
    taskName
    isClosed
    isBilled
    billable
    budgeted
    billableRate
    costRate
  }
`;

const GET_ALL_TIMECARDS_WITH_RANGES = ({ ranges }) => {
  let query = '';
  ranges.forEach(range => { query += getTimeCardsWithRange(range) });
  return gql`
    query getTimeCardsWithRanges {
      ${query}
    }
  `;
}

const GET_ALL_TIMECARDS_BY_DATE = gql`
  query getTimeCardsByDate($from: String!, $to: String!) {
    getTimeCardsByDate(from: $from, to: $to) {
      id
      entryId
      date
      hours
      userId
      userName
      clientId
      clientName
      projectId
      projectName
      taskId
      taskName
      isClosed
      isBilled
      billable
      budgeted
      billableRate
      costRate
    }
  }
`;

const GET_TIMECARDS_BY_PROJECT = gql`
  query getTimeByProject($projectId: ID!, $userId: ID) {
    getTimeEntries(projectId: $projectId, userId: $userId) {
      hours
      cost_rate
      billable_rate
      spent_date
      user {
        id
        name
      }
    }
  }
`;

const GET_TOP_PERFORMERS = gql`
  query getTopPerformersQuery {
    getTopPerformers {
      revenue {
        employee {
          id
          first_name
          last_name
          avatar_url
        }
        billableHours
        revenue
        cost
        profit
        margin
      }
      billing {
        employee {
          id
          first_name
          last_name
          avatar_url
        }
        billableHours
        revenue
        cost
        profit
        margin
      }
      profit {
        employee {
          id
          first_name
          last_name
          avatar_url
        }
        billableHours
        revenue
        cost
        profit
        margin
      }
      margin {
        employee {
          id
          first_name
          last_name
          avatar_url
        }
        billableHours
        revenue
        cost
        profit
        margin
      }
    }
  }
`;

export {
  GET_TIMECARDS_BY_GROUP,
  GET_TIMECARDS_BY_PROJECT,
  GET_ALL_TIMECARDS_BY_DATE,
  GET_ALL_TIMECARDS_WITH_RANGES,
  GET_TOP_PERFORMERS,
};
