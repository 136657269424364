import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Grid, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';

import { useRanglrContext } from 'context/provider.ranglr';
import { GET_ALL_ACCOUNTS } from 'queries';
import OpportunitiesList from 'components/molecules/OpportunitiesList';
import Loading from 'components/atoms/Loading';
import { useStyles } from './Accounts.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const AccountsComponent = ({ accountId }) => {
  const classes = useStyles();
  const [activeAccount, setActiveAccount] = useState();
  const [selectedTab, setSelectedTab] = useState(0);

  const [, ranglrDispatch] = useRanglrContext();
  useEffect(() => {
    ranglrDispatch({
      type: 'SET_BREADCRUMB',
      payload: { currentPage: 'Accounts' },
    });
  }, [ranglrDispatch]);

  const { loading, data: { getAccounts: accounts = [] } = {} } = useQuery(GET_ALL_ACCOUNTS);

  accounts.sort((a, b) => {
    if (a.Name < b.Name) return -1;
    if (a.Name > b.Name) return 1;
    return 0;
  });

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      if (accountId) {
        const filteredAccount = accounts.filter(a => a.Id === accountId) || 0;
        setActiveAccount(filteredAccount[0]);
      } else {
        setActiveAccount(accounts[0]);
      }
    }
  }, [accounts, accountId]);

  const handleAccountChange = event => {
    const accDetails = accounts.filter(ac => ac.Id === event.target.value);
    if (accDetails.length > 0) {
      setActiveAccount(accDetails[0]);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const opportunityPanel = () => (
    <>
      <Grid item xs={12} sm={12}>
        <OpportunitiesList accountId={activeAccount.Id} />
      </Grid>
    </>
  );


  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={classes.header}>
        {activeAccount && (
          <Grid container spacing={3} direction="row" alignItems="flex-end" justify="space-between">
            <Grid item xs={12} sm={9}>
              <Typography variant="h1">{activeAccount.Name}</Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.selectGrid}>
              <Select
                value={activeAccount ? activeAccount.Id : null}
                onChange={handleAccountChange}
                inputProps={{
                  name: 'account',
                  id: 'account',
                }}
              >
                {accounts.map(acc => (
                  <MenuItem key={acc.Id} value={acc.Id}>
                    {acc.Name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
      </div>
      <div className={classes.main}>
        {activeAccount && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <AppBar position="static" color="secondary">
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Opportunities" />
                </Tabs>
              </AppBar>
            </Grid>
            {selectedTab === 0 && opportunityPanel()}
          </Grid>
        )}
      </div>
    </>
  );
};

export default AccountsComponent;
