import { styled, makeStyles } from '@material-ui/core/styles';

import { Box, ListItem, ListItemText } from '@material-ui/core';

export const EstimationPropagation = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const ListTitle = styled(ListItemText)(() => ({
  fontWeight:'bold',
  display:'block'
}))


export const GroupAccordionComp = styled(Box)(() => ({
  display: 'block',
  borderBottom: `1px solid #a8a8a8`,
}));

export const GroupAccordionTitle = styled(Box)(({theme}) => ({
  display: 'block',
  padding: `${theme.spacing(2)}px 0`,

}));

export const GroupAccordionBody = styled(Box)(({theme, expanded}) => ({
  display: 'block',
  maxHeight: expanded ? '1000px' : 0,
  overflow: 'hidden',
  transition: theme.transitions.create('max-height', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '> div': {
    padding: `${theme.spacing(2)}px 0`,
  }
}));

export const EAListItem = styled(ListItem)(() => ({
  display: 'block',
}));

export const EstimateConfigurator = styled(Box)({});

export const GroupAccordionStory = styled(Box)(({theme}) => ({
  padding: `${theme.spacing(2)}px 0`,

}));

export const StickyFooterControls = styled(Box)(({ theme, showLoading }) => ({
  padding: theme.spacing(1),
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  '&:after': {
    content: '""',
    display: showLoading ? 'block' : 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    background: 'rgba(0,0,0,.5)',
  },
}));

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  nested: {
    width: '100%',
    paddingLeft: theme.spacing(4),
  },
  removeFlex: {
    display: 'block',
  },
  listItemText:{
    fontWeight:'bold',
    whiteSpace:'nowrap',
    display:'block',
    width:'30%',
    textAlign:'right',
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
    overflow: 'hidden',
    '& .MuiGrid-container': {
      marginBottom: theme.spacing(3),
    },
    '& .MuiGrid-item': {
      marginRight: theme.spacing(3),
    }
  },
  tableHead: {
    padding: `${theme.spacing(2)}px 0`,
    color: '#a8a8a8',
    fontWeight: 'bold',
  }
}));
