import { styled, makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';

export const EstimateStory = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(6)}px`,
}));

export const TaskContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export const StoryTitle = styled('button')(({ theme }) => ({
  outline: 'none',
  border: 'none',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3),
  position: 'relative',
  cursor: 'pointer',
  '& .title': {
    display: 'flex',
    flexDirection: 'row',
  },
  '& .arrow': {
    display: 'block',
    '&:after': {
      display: 'block',
      'content': ' ',
      position: 'absolute',
      width: '2px',
      height: '10px',
      background: 'black',
    },
  },
  marginBottom: theme.spacing(1),
}));

export const StoryDetails = styled(Box)(({ theme, expanded }) => ({
  maxHeight: expanded ? `1000px` : '0',
  overflow: 'scroll',
  margin: `0 0 0 ${theme.spacing(3)}px`,
  transition: theme.transitions.create('max-height', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));


export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '80vw',
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: '80vw',
    background: 'white',
  },
}));
