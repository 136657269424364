import React, { useState, useEffect } from 'react';
import { Typography, Grid, IconButton, Drawer } from '@material-ui/core';

import { Delete, Edit } from '@material-ui/icons';
import rolesConfig from 'config/roles';
import StoryEditor from 'components/molecules/StoryEditor';
import useEstimateUtils from 'utils/useEstimateUtils';
import { useEstimateContext } from 'context/provider.ranglr';

import { truncate } from 'utils/formatters';
import { EstimateStory, useStyles } from './EstimateStory.style';

const EstimateStoryComponent = ({ story, isApproved }) => {
  const [edit, setEdit] = useState(false);
  const [storyState, setStoryState] = useState(story);
  const { deleteStory, saveStory } = useEstimateUtils();
  const [estimateContext] = useEstimateContext();
  const { rateCard } = estimateContext;
  const roles = rateCard.map(r => r.title);
  const classes = useStyles();
  const { title, description, low, high, multiplier = 1 } = story;

  const domains = {};
  Object.keys(rolesConfig.roles).forEach(a => {
    domains[a] = a;
  });

  useEffect(() => {
    setStoryState(story);
  }, [story])
  

  const handleStorySave = upStory => {
    const newStory = { ...upStory };
    newStory.isLocalUpdate = true;
    saveStory(newStory);
  };

  return (
    <EstimateStory>
      <Grid container className="title" justify="space-between">
        <Grid container xs={3} direction="row" alignItems="flex-start">
          <Typography>{title}</Typography>
        </Grid>
        <Grid container xs={4}>
          <Typography>{truncate(description, 100)}</Typography>
        </Grid>
        <Grid container xs={1} alignItems="center" justify="center">
          <Typography>{low}</Typography>
        </Grid>
        <Grid container xs={1} alignItems="center" justify="center">
          <Typography>{high}</Typography>
        </Grid>
        <Grid container xs={1} alignItems="center" justify="center">
          <Typography>{multiplier}</Typography>
        </Grid>
        <Grid container xs={1} alignItems="center" justify="center">
          {!isApproved && (
            <IconButton aria-label="edit" onClick={() => setEdit(true)}>
              <Edit />
            </IconButton>
          )}
        </Grid>
        <Grid container xs={1} alignItems="center" justify="center">
          {!isApproved && (
            <IconButton aria-label="delete" onClick={() => deleteStory(story)}>
              <Delete />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="temporary"
        anchor="right"
        open={edit}
        onClose={() => setEdit(false)}
      >
        <StoryEditor
          story={storyState}
          handleUpdate={setStoryState}
          saveStory={handleStorySave}
          handleClose={() => setEdit(false)}
          roles={roles}
        />
      </Drawer>
    </EstimateStory>
  );
};

export { EstimateStory as EstimateStoryVanlla };
export default EstimateStoryComponent;
