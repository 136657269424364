import React, { useEffect } from 'react';
import { FlagsProvider } from 'components/organisms/Flags';
import AuthStore from '../auth/auth.store';
import {
  initialState as ranglrInitialState,
  reducer as ranglrReducer,
} from './context.ranglr';
import {
  initialState as estimateInitialState,
  reducer as estimateReducer,
} from './context.estimate';
import {
  initialState as authInitialState,
  reducer as authReducer,
} from './context.auth';

const RanglrStateContext = React.createContext();
const RanglrDispatchContext = React.createContext();
const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();
const EstimateStateContext = React.createContext();
const EstimateDispatchContext = React.createContext();

const RanglrProvider = ({ history, children }) => {
  const [ranglrState, ranglrDispatch] = React.useReducer(
    ranglrReducer,
    ranglrInitialState,
  );
  const [authState, authDispatch] = React.useReducer(
    authReducer,
    authInitialState,
  );
  const [estimateState, estimateDispatch] = React.useReducer(
    estimateReducer,
    estimateInitialState,
  );

  const {
    authStore,
    attributes,
    impersonationRole,
    permissions,
    userId,
    userPermissionLevel,
    officialRole,
  } = authState;

  useEffect(() => {
    const newAuthStore = new AuthStore(history);
    const signIn = () => newAuthStore.signIn();
    const signOut = () => newAuthStore.signOut();
    authDispatch({
      type: 'SET',
      payload: {
        authStore: newAuthStore,
        signIn,
        signOut,
      },
    });
  }, [history]);

  useEffect(() => {
    const handleLoggedInDispatch = attrs => {
      authStore.currentAuthenticatedUser().then(userParam => {
        const { jwtToken } =
          (userParam &&
            userParam.signInUserSession &&
            userParam.signInUserSession.idToken) ||
          {};
        authDispatch({
          type: 'SET',
          payload: {
            loaded: true,
            user: userParam,
            token: jwtToken,
            authenticated: jwtToken,
            ...attrs,
          },
        });
      });
    };

    const initAuthentication = async () => {
      if (authStore) {
        // subscribe to changes from auth store
        authStore.subscribe(async newAuth => {
          const authVal = await newAuth;
          handleLoggedInDispatch(authVal);
        });
        // check it the first time
        const auth = await authStore.isAuthenticated();
        if (auth) {
          const attributesParam = await authStore.getCurrentUser();
          handleLoggedInDispatch(attributesParam);
        } else {
          authDispatch({
            type: 'SET',
            payload: { loaded: true, authenticated: auth },
          });
        }
        return () => {
          authStore.unsubscribe();
        };
      }
      return () => {};
    };
    initAuthentication();
  }, [authStore]);

  useEffect(() => {
    const dynamoPermissions = permissions.filter(p => p.id === userId);

    const officialRoleTemp =
      (dynamoPermissions &&
        dynamoPermissions[0] &&
        dynamoPermissions[0].level) ||
      (attributes && attributes['custom:role']);

    const tempRole = impersonationRole || officialRoleTemp;

    authDispatch({
      type: 'SET',
      payload: { userPermissionLevel: tempRole, officialRoleTemp },
    });
  }, [permissions, attributes, impersonationRole]);

  const flags = {
    officialRole,
    role: userPermissionLevel,
    isAdmin: userPermissionLevel === 'admin',
    isLeadership:
      userPermissionLevel === 'leadership' || userPermissionLevel === 'admin',
    isAccount:
      userPermissionLevel === 'account' ||
      userPermissionLevel === 'leadership' ||
      userPermissionLevel === 'admin',
    isProjectManager:
      userPermissionLevel === 'projectManager' ||
      userPermissionLevel === 'account' ||
      userPermissionLevel === 'leadership' ||
      userPermissionLevel === 'admin',
  };

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={authDispatch}>
        <RanglrStateContext.Provider value={ranglrState}>
          <RanglrDispatchContext.Provider value={ranglrDispatch}>
            <EstimateStateContext.Provider value={estimateState}>
              <EstimateDispatchContext.Provider value={estimateDispatch}>
                <FlagsProvider flags={flags}>{children}</FlagsProvider>
              </EstimateDispatchContext.Provider>
            </EstimateStateContext.Provider>
          </RanglrDispatchContext.Provider>
        </RanglrStateContext.Provider>
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useRanglrState = () => {
  const context = React.useContext(RanglrStateContext);
  if (context === undefined) {
    throw new Error('useRanglrState must be used within a RanglrProvider');
  }
  return context;
};
const useRanglrDispatch = () => {
  const context = React.useContext(RanglrDispatchContext);
  if (context === undefined) {
    throw new Error('useRanglrDispatch must be used within a RanglrProvider');
  }
  return context;
};
const useAuthState = () => {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a RanglrProvider');
  }
  return context;
};
const useAuthDispatch = () => {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a RanglrProvider');
  }
  return context;
};
const useEstimateState = () => {
  const context = React.useContext(EstimateStateContext);
  if (context === undefined) {
    throw new Error('useEstimateState must be used within a RanglrProvider');
  }
  return context;
};
const useEstimateDispatch = () => {
  const context = React.useContext(EstimateDispatchContext);
  if (context === undefined) {
    throw new Error('useEstimateDispatch must be used within a RanglrProvider');
  }
  return context;
};

const useRanglrContext = () => [useRanglrState(), useRanglrDispatch()];
const useAuthContext = () => [useAuthState(), useAuthDispatch()];
const useEstimateContext = () => [useEstimateState(), useEstimateDispatch()];

export { RanglrProvider, useRanglrContext, useAuthContext, useEstimateContext };
