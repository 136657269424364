import { styled } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import { palette } from '../../../theme/colors';

export const GradientContainer = styled(Box)({
  position: 'absolute',
  bottom: '0',
  right: '0',
  width: '100%',
  height: '50%',
  background: 'linear-gradient(to bottom, rgba(0,0,0,.0), rgba(0,0,0,1.0))',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
});

export const LoginTextContainer = styled(Box)({
  padding: '50px 50px 100px 50px',
  width: '50%',
  color: '#ffffff',
});

export const LoginH1 = styled(Typography)({
  fontWeight: 400,
  fontFamily: 'Bevan',
  fontSize: 40,
  paddingBottom: 5,
  borderWidth: '0 0 3px 0',
  display: 'block',
  borderStyle: 'solid',
  borderColor: palette.accentGreen.main,
});

export const LoginH2 = styled(Typography)({
  paddingTop: 15,
  fontWeight: 700,
});
