import gql from 'graphql-tag';

const GET_STORIES = gql`
  query getStoriesQuery {
    getStories {
      id
      title
      description
      high
      low
      domain
      tasks
      multiplier
      clonedFromId
    }
  }
`;

const SAVE_STORY = gql`
  mutation createStoryQuery(
    $id: ID
    $title: String!
    $description: String
    $high: Float
    $low: Float
    $domain: String
    $tasks: String
    $returnSingleStory: Boolean
    $multiplier: Int
    $clonedFromId: ID
  ) {
    createStory(
      id: $id
      title: $title
      description: $description
      high: $high
      low: $low
      domain: $domain
      tasks: $tasks
      returnSingleStory: $returnSingleStory
      multiplier: $multiplier
      clonedFromId: $clonedFromId
    ) {
      id
      title
      description
      high
      low
      domain
      tasks
      multiplier
      clonedFromId
    }
  }
`;

const SAVE_BULK_STORIES = gql`
  mutation createBulkStoryQuery(
    $stories: [StoryInput!]!
  ) {
    createBulkStories(
      stories: $stories
    ) {
      id
      title
      description
      high
      low
      domain
      tasks
    }
  }
`;

const DELETE_STORY = gql`
  mutation deleteStoryQuery($id: ID!) {
    deleteStory(id: $id) {
      id
    }
  }
`;

export { GET_STORIES, SAVE_STORY, SAVE_BULK_STORIES, DELETE_STORY };
