import { styled } from '@material-ui/core/styles';

import { Box, Button, Typography } from '@material-ui/core';


export const Instructions = styled(Typography)(({theme}) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

export const Completed = styled(Instructions)(() => ({
  display: 'inline-block',
}))


export const EstimationStepper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  background: 'white',
  width: '100%',
}));

export const StepperButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const StepperButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));
