import { makeStyles } from '@material-ui/core/styles';
import { chips } from 'config/roles';

const buildChipClasses = palette => {
  const styles = {};
  const keys = Object.keys(chips);
  keys.forEach(key => {
    styles[`&.${chips[key].id}`] = {
      backgroundColor: palette[chips[key].color].main,
    };
  });
  return styles;
};

export const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
    backgroundColor: 'white',
    padding: 30,
  },
  main: {
    padding: spacing(3),
  },
  chip: {
    marginLeft: spacing(1),
    ...buildChipClasses(palette),
    color: 'white',
  },
}));
