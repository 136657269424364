import gql from 'graphql-tag';

const GET_PERMISSIONS = gql`
  query getPermissionsQuery {
    getPermissions {
      id
      level
    }
  }
`;

const SAVE_PERMISSION = gql`
  mutation createPermissionMutation($id: ID!, $level: String!) {
    createPermission(id: $id, level: $level) {
      id
      level
    }
  }
`;

export { GET_PERMISSIONS, SAVE_PERMISSION };
