import React, { useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Drawer } from '@material-ui/core';
import { DELETE_STORY, SAVE_STORY } from 'queries';
import StoryEditor from 'components/molecules/StoryEditor';
import { useEstimateContext } from 'context/provider.ranglr';
import { useStyles } from './CreateStoryButton.style';
/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const CreateStoryButton = ({
  dispatch,
  label,
  activeStory: activeStoryProp,
}) => {
  const classes = useStyles();
  const [estimateState, estimateDispatch] = useEstimateContext();
  const [saveStory] = useMutation(SAVE_STORY);
  const { activeStory } = estimateState;

  const [deleteStory, { data: deleteSuccess }] = useMutation(DELETE_STORY, {
    variables: activeStory ? { ...activeStory } : { ...activeStoryProp },
    refetchQueries: ['getStoriesQuery'],
  });

  useEffect(() => {
    if (deleteSuccess) {
      dispatch({ type: 'CLOSE_MODAL' });
    }
  }, [deleteSuccess, dispatch]);

  const handleClose = () => {
    if (dispatch)
      dispatch({
        type: 'CLOSE_MODAL',
        payload: {
          activeStory: null,
        },
      });

    estimateDispatch({
      type: 'CLOSE_STORY_MODAL',
      payload: {
        activeStory: null,
      },
    });
  };

  const handleSaveStory = story => {
    const newStory = { ...story };
    if (typeof newStory.tasks !== 'string') {
      newStory.tasks = JSON.stringify(newStory.tasks);
    }
    saveStory({
      variables: newStory,
      refetchQueries: ['getStoriesQuery'],
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() =>
          estimateDispatch({
            type: 'SET_ACTIVE_STORY',
            payload: { activeStory: {} },
          })
        }
      >
        {label || 'Add Story'}
      </Button>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="temporary"
        anchor="right"
        open={activeStory || activeStoryProp}
        onClose={handleClose}
      >
        {(activeStory || activeStoryProp) && (
          <StoryEditor
            handleUpdate={newActive =>
              estimateDispatch({
                type: 'SET_ACTIVE_STORY',
                payload: { activeStory: newActive },
              })
            }
            saveStory={handleSaveStory}
            handleClose={handleClose}
            handleDelete={deleteStory}
            story={activeStory || activeStoryProp}
          />
        )}
      </Drawer>
    </>
  );
};

export default CreateStoryButton;
