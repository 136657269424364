import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Table from 'components/molecules/Table';
import { navigate } from '@reach/router';
import { Chip } from '@material-ui/core';

import { GET_ALL_ESTIMATES } from 'queries';
import { useStyles } from './EstimationListings.style';

const aggregateTableData = ({ estimates, opportunities }) =>
  estimates.map(estimate => {
    const opp = opportunities.filter(o => o.Id === estimate.opportunityId);
    const oppCount = estimates.filter(e => e.opportunityId === estimate.opportunityId).length;
    return { ...estimate, opportunity: opp[0], numEstimates: oppCount };
  });

const getPill = (rowData, classes) => {
  if (!rowData || !rowData.opportunity) {
    return null;
  }
  let className = 'grayPill';
  if (rowData.opportunity.Probability <= 100 && rowData.opportunity.Probability >= 50) {
    className = 'redPill';
  }
  return <Chip label={rowData.opportunity.Probability} className={classes[className]} />;
};

const removeDuplicates = (myArr, prop) =>
  myArr.filter((obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);

const EstimationListingsComponent = () => {
  const { data: { getEstimates: estimates = [], getOpportunities: opportunities = [] } = {} } = useQuery(
    GET_ALL_ESTIMATES,
  );

  const classes = useStyles();
  const tableData = removeDuplicates(aggregateTableData({ estimates, opportunities }), 'opportunityId');

  return (
    <Table
      onRowClick={(e, rowData) => navigate(`/accounts/${rowData.accountId}/${rowData.opportunityId}`)}
      title="Active Estimations"
      columns={[
        {
          title: 'Probability',
          field: 'opportunity.Probability',
          render: rowData => getPill(rowData, classes),
          defaultSort: 'desc',
        },
        { title: 'Account', field: 'opportunity.Account.Name' },
        { title: 'Opportunity', field: 'opportunity.Name' },
        {
          title: 'Number of Estimates',
          field: 'numEstimates',
        },
      ]}
      data={tableData}
      styles={{
        toolbar: classes.toolbar,
      }}
      options={{
        pageSize: 10,
      }}
    />
  );
};

export default EstimationListingsComponent;
