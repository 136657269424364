import Amplify, { Auth, Hub } from 'aws-amplify';
import { awsConfig, signInUrl } from './auth.config';

class AuthStore {
  constructor(history) {
    this.history = history;
    this.registerHubListener();
    Amplify.configure(awsConfig);
  }

  subscribe = subscriber => {
    this.subscriber = subscriber;
  };

  unsubscribe = () => {
    this.subscriber = null;
  };

  notify = authenticated => {
    if (this.subscriber) this.subscriber(authenticated);
  };

  isAuthenticated = async () => Boolean(Auth.userPool.getCurrentUser());

  getCurrentUser = async () => {
    try {
      const attributes = await Auth.currentUserInfo();
      return attributes;
    } catch (err) {
      return null;
    }
  };

  getCredentials = async () => {
    try {
      const credentials = await Auth.currentCredentials();
      this.notify(credentials.authenticated);
      return credentials;
    } catch (err) {
      this.notify(false);
      return {};
    }
  };

  currentAuthenticatedUser = async () => {
    try {
      const session = await Auth.currentAuthenticatedUser();
      return session;
    } catch (err) {
      return {};
    }
  };

  currentSession = async () => {
    try {
      const session = await Auth.currentSession();
      return session;
    } catch (err) {
      return {};
    }
  };

  registerHubListener = () => {
    const self = this;
    Hub.listen('auth', async capsule => {
      if (capsule.payload.event === 'configured') {
        self.notify(self.isAuthenticated('onHubCapsule configured'));
      }
      if (capsule.payload.event === 'cognitoHostedUI') {
        self.notify(self.isAuthenticated('onHubCapsule cognitoHostedUI'));
        self.history.push('/');
      }
    });
  };

  signIn = () => window.location.assign(signInUrl);

  signOut = async () => {
    try {
      await Auth.signOut();
    } catch (e) {
      return e;
    }
    return null;
  };
}

export default AuthStore;
