import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/react-hooks';

import { GET_PERMISSIONS } from 'queries';
import { useRanglrContext, useAuthContext } from 'context/provider.ranglr';
import NavigationAppBar from 'components/molecules/NavigationAppBar';
import CustomSnackbar from 'components/molecules/CustomSnackbar';
import NavigationDrawer from 'components/molecules/NavigationDrawer';
import { useStyles } from './Layout.style';
/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const LayoutComponent = ({ children }) => {
  const [, authDispatch] = useAuthContext();
  const [ranglrState, ranglrDispatch] = useRanglrContext();
  const { message, showMessage, variant } = ranglrState;
  const { data: { getPermissions: permissions = [] } = {} } = useQuery(GET_PERMISSIONS);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (permissions.length > 0) {
      authDispatch({
        type: 'SET',
        payload: {
          permissions,
        },
      });
    }
  }, [authDispatch, permissions]);

  const handleSnackClose = () => {
    ranglrDispatch({ type: 'CLOSE_MESSAGE' });
  };

  return (
    <>
      <div className={classes.navigation}>
        <NavigationAppBar open={open} setOpen={setOpen} />
        <NavigationDrawer open={open} />
      </div>
      <main
        className={clsx(classes.wrapper, {
          [classes.wrapperOpen]: open,
        })}
      >
        {children}
      </main>
      <CustomSnackbar open={showMessage} handleClose={handleSnackClose} variant={variant} message={message} />
    </>
  );
};

export default LayoutComponent;
