import React from 'react';

import { chips } from 'config/roles';
import { Pill, PillWrapper } from './Filters.style';

const FiltersComponent = ({ notClickable, pillFilters, activeFilters, dispatch, filterDispatchType }) => {
  const renderPillFilters = () =>
    pillFilters.map(pill => {
      const isActive = notClickable || (activeFilters && activeFilters.includes(pill) ? 1 : 0);
      if (pill && chips[pill]) {
        return (
          <Pill
            key={pill}
            colorvalue={chips[pill].color}
            label={pill}
            active={isActive}
            onClick={
              !notClickable ? () => dispatch({ type: filterDispatchType, payload: { isActive, filter: pill } }) : null
            }
          />
        );
      }
      return null;
    });

  return <PillWrapper>{pillFilters && pillFilters.length > 0 && renderPillFilters()}</PillWrapper>;
};

export default FiltersComponent;
