const initialState = {
  breadcrumb: [],
  message: '',
  showMessage: false,
  variant: '',
};

const handleSetBreadcrumb = ({ currentPage, crumbs = [] }) => {
  const newCrumbs = [{ title: 'Home', link: '/' }, ...crumbs];
  return {
    breadcrumb: {
      currentPage,
      crumbs: currentPage === 'Home' ? [] : newCrumbs,
    },
  }
};

const reducer = (state, action) => {
  switch (action.type) {
  case 'SET':
    return { ...state, ...action.payload };
  case 'SET_BREADCRUMB':
    return { ...state, ...handleSetBreadcrumb(action.payload) };
  case 'SET_MESSAGE':
    return { 
      ...state,
      variant: action.payload.variant,
      message: action.payload.message,
      showMessage: true,
    };
  case 'CLOSE_MESSAGE':
    return { ...state, showMessage: false };
  default:
    throw new Error(`Unhandled action type in ranglr reducer for: ${action.type}`);
  }
};

export {
  reducer,
  initialState,
}
