import React from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/react-hooks';
import { ListItem, ListItemAvatar, Avatar, Typography } from '@material-ui/core';

import { useAuthContext } from 'context/provider.ranglr';
import { GET_GSUITE_EMPLOYEE } from 'queries';
import Styles from './Profile.style';

const ProfileComponent = ({ open }) => {
  const [authState] = useAuthContext();
  const { user } = authState;
  const { data: { getGSuiteEmployee: userInfo = {} } = {} } = useQuery(GET_GSUITE_EMPLOYEE, {
    variables: { userKey: user.attributes.email },
  });

  const { name = {}, photoData, thumbnailPhotoUrl } = userInfo || {};
  const classes = Styles();
  const greeting = open && name ? `Hey, ${name.givenName}!` : <span>&nbsp;</span>;

  return (
    <div
      className={clsx(classes.profileWrapper, {
        [classes.profileWrapperClosed]: !open,
      })}
    >
      <ListItem alignItems="flex-start">
        <ListItemAvatar
          className={classes.imageWrapper}
        >
          <Avatar
            className={`${classes.imageIcon} ${open ? classes.bigImageIcon : ''}`}
            alt={`${name.givenName && name.givenName} ${name.familyName && name.familyName}`}
            src={
              photoData
                ? `data:image/jpeg;base64,${photoData.replace(/_/g, '/').replace(/-/g, '+')}`
                : thumbnailPhotoUrl
            }
          />
        </ListItemAvatar>
      </ListItem>
      <Typography className={classes.name} align="center" variant="subtitle1">
        {greeting}
      </Typography>
    </div>
  );
};

export default ProfileComponent;
