export const typography = {
  fontFamily: ['Open Sans', 'Quicksand', 'Bevan'].join(', '),
  h1: {
    fontSize: 24,
  },
  h2: {
    fontSize: 24,
  },
  h3: {
    fontSize: 24,
  },
  h4: {
    fontSize: 24,
  },
  h5: {
    fontSize: 24,
  },
  h6: {
    fontSize: 16,
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 300,
  },
  body2: {
    fontSize: 14,
  },
};
