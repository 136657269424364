module.exports = {
  roles: {
    Technology: [
      'Associate Technology',
      'Senior Technology',
      'Manager Technology',
    ],
    'Visual Design': [
      'Associate Visual Design',
      'Senior Visual Design',
      'Manager Visual Design',
    ],
    Account: [
      'Associate Account',
      'Senior Account',
      'Manager Account',
    ],
    UX: [
      'Associate UX',
      'Senior UX',
      'Manager UX',
    ],
    Strategy: [
      'Associate Strategy',
      'Senior Strategy',
      'Manager Strategy',
    ],
    Product: [
      'Associate Product',
      'Senior Product',
      'Manager Product',
    ],
    'Project Management': [
      'Associate Project Management',
      'Senior Project Management',
      'Manager Project Management',
    ],
    Consulting: [
      'Associate Consulting',
      'Senior Consulting',
      'Manager Consulting',
    ],
  },
  chips: {
    Technology: {
      'id': 'T',
      'color': 'accentGreen',
    },
    'Visual Design': {
      'id': 'D',
      'color': 'accentPurple',
    },
    Account: {
      'id': 'A',
      'color': 'accentRed',
    },
    UX: {
      'id': 'X',
      'color': 'accentGreen',
    },
    Strategy: {
      'id': 'S',
      'color': 'accentPurple',
    },
    Product: {
      'id': 'R',
      'color': 'accentRed',
    },
    'Project Management': {
      'id': 'P',
      'color': 'accentGreen',
    },
    Consulting: {
      'id': 'C',
      'color': 'accentPurple',
    },
  }
}
