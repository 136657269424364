import { styled, makeStyles } from '@material-ui/core/styles';

import { Box, Paper } from '@material-ui/core';

export const EstimateGroup = styled(Box)(({ even }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: even ? '#edeef5' : '#fff'
}));

export const GroupActions = styled(Box)(({ theme }) => ({
  // paddingLeft: theme.spacing(6),
  background: 'transparent',
  padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(9)}px`,
}));


export const TitleContainer = styled(Paper)(({ theme, expanded }) => ({
  background: expanded ? '#F4F4F4' : 'white',
  padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
  transition: theme.transitions.create('background', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const ArrowContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(6),
}));

export const GroupTitle = styled('button')(({ theme }) => ({
  outline: 'none',
  border: 'none',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(3),
  position: 'relative',
  cursor: 'pointer',
  '& .title': {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const GroupDetails = styled(Paper)(({ theme, expanded }) => ({
  maxHeight: expanded ? `1000px` : '0',
  overflow: 'scroll',
  transition: theme.transitions.create('max-height', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  
}));

export const GroupDetailsInner = styled(Box)(({theme}) => ({
  padding: `${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
}));

export const useStyles = makeStyles(theme => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
  speedDial: {
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    transform: 'translateY(-20px)',
    top: '50%',
    '&$directionUp, &$directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
    '&$directionDown, &$directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(3),
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '80vw',
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: '70vw',
    background: 'white',
  },
  directionUp: {},
  directionRight: {},
  directionDown: {},
  directionLeft: {},
  pulse: {},
}));
