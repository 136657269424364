import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Typography } from '@material-ui/core';

import { GET_TAGS, UPDATE_TAG, DELETE_TAG } from 'queries';
import { useRanglrContext } from 'context/provider.ranglr';
import Table from 'components/molecules/Table';
import { types } from 'config/taxonomy';
import { useStyles } from './TagManagement.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const TagManagementComponent = () => {
  const [, ranglrDispatch] = useRanglrContext();
  const { data: { getTag: tags = [] } = {} } = useQuery(GET_TAGS);
  const [updateTag, { data: updateTagSuccess }] = useMutation(UPDATE_TAG);
  const [deleteTag, { data: deleteTagSuccess }] = useMutation(DELETE_TAG);

  useEffect(() => {
    ranglrDispatch({ type: 'SET_BREADCRUMB', payload: { currentPage: 'Tags' } });
  }, [ranglrDispatch]);

  useEffect(() => {
    if(updateTagSuccess) {
      ranglrDispatch({ type: 'SET_MESSAGE', payload: { variant: 'success', message: 'Tag Saved' } });
    }
  }, [updateTagSuccess]);

  useEffect(() => {
    if (deleteTagSuccess) {
      ranglrDispatch({ type: 'SET_MESSAGE', payload: { variant: 'success', message: 'Tag Deleted' } });
    }
  }, [deleteTagSuccess]);

  const parents = {};
  
  tags.forEach(({ id, parentId, label }) => {
    if(!parentId) {
      parents[id] = label;
    }
  });

  const handleTask = (taskType, {
    id,
    parentId,
    label,
    type,
  }) => {
    switch (taskType) {
    case "EDIT":
      updateTag({
        variables: {
          id,
          parentId,
          label,
          type,
        },
        refetchQueries: ['getTagsQuery'],
      });
      break;
    case "NEW":
      updateTag({
        variables: {
          parentId,
          label,
          type,
        },
        refetchQueries: ['getTagsQuery'],
      });
      break;
    case "DELETE":
      if (!parentId) {
        const children = tags.filter((tag) => tag.parentId === id);
        if(children.length > 0) {
          ranglrDispatch({
            type: 'SET_MESSAGE',
            payload: { variant: 'error', message: 'This tag contains children, delete all children' }
          });
          break;
        }
      }
      deleteTag({
        variables: { id },
        refetchQueries: ['getTagsQuery'],
      });
      break;
    default:
      throw new Error();
    }
  };

  const { header, main, toolbar } = useStyles();

  return (
    <>
      <div className={header}>
        <Typography variant="h1" component="span">Taxonomy</Typography>
      </div>
      <div className={main}>
        {tags && <Table
          editable={{
            onRowAdd: newData =>
              new Promise(resolve => {
                handleTask('NEW', newData);
                resolve();
              }),
            onRowUpdate: newData =>
              new Promise(resolve => {
                handleTask('EDIT', newData);
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise(resolve => {
                handleTask('DELETE', oldData);
                resolve();
              }),
          }}
          columns={[
            { title: 'Type', field: 'type', lookup: types },
            { title: 'Label', field: 'label' },
            { title: 'Parent', field: 'parentId', lookup: parents },
          ]}
          data={tags}
          styles={{
            toolbar,
          }}
          title="Tag Manager"
          options={{
            pageSize: 10,
          }}
        />}
      </div>
    </>
  )
};

export default TagManagementComponent;
