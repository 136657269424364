import Amplify from 'aws-amplify';

const {
  REACT_APP_OAUTH_DOMAIN,
  REACT_APP_SIGNIN_CALLBACK,
  REACT_APP_SIGNOUT_CALLBACK,
  REACT_APP_REGION,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_CLIENT_ID,
} = process.env;

const oauth = {
  domain: REACT_APP_OAUTH_DOMAIN,
  scope: [
    'email',
    'profile',
    'openid',
    'aws.cognito.signin.user.admin',
  ],
  redirectSignIn: REACT_APP_SIGNIN_CALLBACK,
  redirectSignOut: REACT_APP_SIGNOUT_CALLBACK,
  responseType: 'code',
};

export const awsConfig = {
  Analytics: {
    disabled: true,
  },
  Auth: {
    oauth,
    region: REACT_APP_REGION,
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_CLIENT_ID,
  },
};

function configureAmplify() {
  Amplify.configure(awsConfig);
}

export const signInUrl = `https://${awsConfig.Auth.oauth.domain}/oauth2/authorize
?identity_provider=G-Suite&redirect_uri=${awsConfig.Auth.oauth.redirectSignIn}
&response_type=${awsConfig.Auth.oauth.responseType}
&client_id=${awsConfig.Auth.userPoolWebClientId}
&scope=aws.cognito.signin.user.admin email openid profile`;

export default configureAmplify;
