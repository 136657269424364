import React, { useState } from 'react';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

import {
  GroupAccordionComp,
  GroupAccordionTitle,
  GroupAccordionBody,
  GroupAccordionStory,
  useStyles,
} from './EstimatePropogation.style';

const GroupAccordion = ({ group }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const { name, stories } = group;
  let high = 0;
  let low = 0;
  if (stories) {
    stories.forEach(s => {
      high += s.high;
      low += s.low;
    });
  }

  return (
    <GroupAccordionComp>
      <GroupAccordionTitle onClick={() => setIsExpanded(!isExpanded)}>
        <Grid container>
          <Grid container xs={1} alignItems="center" justify="center">
            {isExpanded ? <ExpandLess /> : <ExpandMore />}
          </Grid>
          <Grid container xs={3}>
            {name}
          </Grid>
          <Grid container xs={2}>
            {high} hours
          </Grid>
          <Grid container xs={2}>
            {low} hours
          </Grid>
        </Grid>
      </GroupAccordionTitle>
      <GroupAccordionBody expanded={isExpanded}>
        <Grid container className={classes.tableHead}>
          <Grid container xs={1} alignItems="center" justify="center">
          </Grid>
          <Grid container xs={3}>
            Stories
          </Grid>
        </Grid>
        {stories &&
          stories.map(s => (
            <GroupAccordionStory>
              <Grid container>
                <Grid
                  container
                  xs={1}
                  alignItems="center"
                  justify="center"
                ></Grid>
                <Grid container xs={3}>
                  {s.title}
                </Grid>
                <Grid container xs={2}>
                  {s.high} hours
                </Grid>
                <Grid container xs={2}>
                  {s.low} hours
                </Grid>
              </Grid>
            </GroupAccordionStory>
          ))}
      </GroupAccordionBody>
    </GroupAccordionComp>
  );
};
export default GroupAccordion;
