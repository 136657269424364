import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  IconButton,
  Input,
  Drawer,
} from '@material-ui/core';
import { ExpandMore, ExpandLess, Delete, Done, Edit } from '@material-ui/icons';

import EstimateStory from 'components/molecules/EstimateStory';
import { useEstimateContext } from 'context/provider.ranglr';
import StoryEditor from 'components/molecules/StoryEditor';
import useEstimateUtils from 'utils/useEstimateUtils';

import {
  EstimateGroup,
  TitleContainer,
  GroupDetails,
  GroupDetailsInner,
  ArrowContainer,
  GroupActions,
  useStyles,
} from './EstimateGroup.style';

const EstimateGroupComponent = ({ group, index, isApproved }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditGroupName, showEditGroupName] = useState(false);
  const [tempGroupName, setTempGroupName] = useState(group.name);
  const [addStory, setAddStory] = useState(false);
  const [tempStory, setTempStory] = useState({});
  const classes = useStyles();
  const [, dispatchEstimateContext] = useEstimateContext();
  const {
    deleteStory,
    saveStoriesToGroup,
    deleteGroup,
    saveGroupName,
  } = useEstimateUtils();
  const { name, stories, id } = group;

  return (
    <EstimateGroup even={index % 2 === 1}>
      <TitleContainer expanded={isOpen}>
        <Grid container onClick={() => setIsOpen(!isOpen)}>
          <Grid container xs={3} direction="row" alignItems="center">
            <ArrowContainer>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ArrowContainer>
            {!isEditGroupName ? (
              <Typography>{name}</Typography>
            ) : (
              <div>
                <Input
                  value={tempGroupName}
                  onChange={e => setTempGroupName(e.target.value)}
                />
                <IconButton
                  aria-label="save group name"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    saveGroupName({ group, newName: tempGroupName });
                    showEditGroupName(false);
                  }}
                >
                  <Done />
                </IconButton>
              </div>
            )}
          </Grid>
          <Grid container xs={4}></Grid>
          <Grid container xs={1} alignItems="center" justify="center"></Grid>
          <Grid container xs={1} alignItems="center" justify="center"></Grid>
          <Grid container xs={1} alignItems="center" justify="center"></Grid>
          <Grid container xs={1} alignItems="center" justify="center">
            {!isApproved && (
              <IconButton
                aria-label="edit group name"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  showEditGroupName(true);
                }}
              >
                <Edit />
              </IconButton>
            )}
          </Grid>

          <Grid container xs={1} alignItems="center" justify="center">
            {!isApproved && (
              <IconButton
                aria-label="delete group"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteGroup(group);
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </TitleContainer>

      <GroupDetails expanded={isOpen}>
        <GroupDetailsInner>
          {stories &&
            stories.map(s => (
              <EstimateStory story={s} isApproved={isApproved} />
            ))}
        </GroupDetailsInner>
        {!isApproved && (
          <GroupActions>
            <Button
              onClick={() =>
                dispatchEstimateContext({
                  type: 'SHOW_STORY_MODAL',
                  payload: { activeGroup: group },
                })
              }
              className={classes.marginRight}
            >
              Add Existing Stories
            </Button>
            <Button onClick={() => setAddStory(true)}>Create New Story</Button>
          </GroupActions>
        )}
      </GroupDetails>
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="temporary"
        anchor="right"
        open={addStory}
        onClose={() => setAddStory(false)}
      >
        <StoryEditor
          story={tempStory}
          handleDelete={() => deleteStory(tempStory)}
          handleClose={() => {
            setAddStory(false);
            setTempStory({});
          }}
          handleUpdate={setTempStory}
          saveStory={newStories => saveStoriesToGroup([newStories], id)}
        />
      </Drawer>
    </EstimateGroup>
  );
};

export { EstimateGroup as EstimateGroupVanlla };
export default EstimateGroupComponent;
