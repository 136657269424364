import React from 'react';
import EstimatePropogation from 'components/molecules/EstimatePropogation';

const EstimateAccepted = props => <>
  <EstimatePropogation {...props} />
</>



export default EstimateAccepted;
