import { makeStyles, styled } from '@material-ui/styles';
import { Box, ExpansionPanel, ExpansionPanelDetails, TextField, ExpansionPanelSummary } from '@material-ui/core';

export const Handle = styled(Box)(({ theme }) => ({
  marginRight: `${theme.spacing(2)}px`,
  display: 'flex',
  alignItems: 'center',
}));

export const Multiplier = styled(TextField)(() => ({
  margin: 0,
  display: 'block',
}));

export const Effort = styled(Box)(({ theme }) => ({
  marginRight: `${theme.spacing(2)}px`,
  alignItems: 'center',
  display: 'flex',
}));

export const Footer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${theme.spacing(2)}px 0`,
}));

export const ExpansionPanelComponent = styled(ExpansionPanel)(({ theme }) => ({
  boxShadow: `0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);`,
  marginBottom: `${theme.spacing(2)}px !important`,
}));

export const ExpansionPanelDetailsComponent = styled(ExpansionPanelDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ExpansionPanelSummaryComponent = styled(ExpansionPanelSummary)(() => ({
  '&.Mui-focused': {
    background: 'white',
  },
}));

export const useStyles = makeStyles(theme => ({
  icon: {
    marginLeft: theme.spacing(1),
  },
  toolbar: {
    backgroundColor: theme.palette.grey.main,
  },
  hoursPerDay: { padding: 5 },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));
