import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => {
  const { zIndex, transitions, palette, breakpoints, spacing } = theme;

  const toolbarHeights = {
    med: 56,
    xs: 48,
    sm: 64,
  };

  const logo = {
    logo: {
      fontSize: 30,
      fontWeight: 300,
      fontFamily: 'Quicksand',
      color: palette.grey.main,
    },
    logoWrapper: {
      backgroundColor: palette.secondary.dark,
      position: 'fixed',
      zIndex: 9999,
      top: 0,
      left: 0,
      width: drawerWidth,
      height: toolbarHeights.med + 1,
      [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
        height: toolbarHeights.xs + 1,
      },
      [breakpoints.up('sm')]: {
        height: toolbarHeights.sm + 1,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 'none',
      cursor: 'pointer',
      border: 'none',
      transition: transitions.create('width', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    logoWrapperClosed: {
      width: spacing(7) + 1,
      [breakpoints.up('sm')]: {
        width: spacing(9) + 1,
      },
      transition: transitions.create(['width'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
  };

  const toolbar = {
    toolbar: {
      height: toolbarHeights.med,
      [`${breakpoints.up('xs')} and (orientation: landscape)`]: {
        height: toolbarHeights.xs,
      },
      [breakpoints.up('sm')]: {
        height: toolbarHeights.sm,
      },
    },
    toolbarClosed: {
      paddingLeft: spacing(7) + 1,
      [breakpoints.up('sm')]: {
        paddingLeft: spacing(9) + 1,
      },
    },
    menuButton: {
      marginRight: spacing(2),
      transition: transitions.create(['marginRight, marginLeft'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    menuButtonClosed: {
      marginLeft: spacing(2),
      transition: transitions.create(['marginRight, marginLeft'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  };

  const appBar = {
    appBar: {
      zIndex: zIndex.drawer + 1,
      transition: transitions.create(['width', 'margin'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.greyLight.main,
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: transitions.create(['width', 'margin'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  };

  return {
    ...logo,
    ...toolbar,
    ...appBar,
  };
});

export default useStyles;
