import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => {
  const {
    transitions
  } = theme;

  return {
    navigation: {
      flexGrow: 1,
    },
    wrapper: {
      position: 'relative',
      margin: '0 0 0 73px',
      minHeight: 'calc(100vh - 65px)',
      transition: transitions.create('margin', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    wrapperOpen: {
      margin: '0 0 0 240px',
      transition: transitions.create('margin', {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  }
});
