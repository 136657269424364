export default {
  root: {
    boxShadow: 'none',
    backgroundColor: 'white',
  },
  elevation0: { boxShadow: 'none' },
  elevation1: { boxShadow: 'none' },
  elevation2: { boxShadow: 'none' },
  elevation3: { boxShadow: 'none' },
  elevation4: { boxShadow: 'none' },
  elevation5: { boxShadow: 'none' },
  elevation6: { boxShadow: 'none' },
  elevation7: { boxShadow: 'none' },
  elevation8: { boxShadow: 'none' },
  elevation9: { boxShadow: 'none' },
  elevation10: { boxShadow: 'none' },
  elevation11: { boxShadow: 'none' },
  elevation12: { boxShadow: 'none' },
  elevation13: { boxShadow: 'none' },
  elevation14: { boxShadow: 'none' },
  elevation15: { boxShadow: 'none' },
  elevation16: { boxShadow: 'none' },
  elevation17: { boxShadow: 'none' },
  elevation18: { boxShadow: 'none' },
  elevation19: { boxShadow: 'none' },
  elevation20: { boxShadow: 'none' },
  elevation21: { boxShadow: 'none' },
  elevation22: { boxShadow: 'none' },
  elevation23: { boxShadow: 'none' },
  elevation24: { boxShadow: 'none' },
};
