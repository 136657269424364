import React, { useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { roles } from 'config/permissions';
import Table from 'components/molecules/Table';
import { useRanglrContext } from 'context/provider.ranglr';
import { SAVE_PERMISSION, GET_PERMISSIONS, GET_ALL_EMPLOYEES } from 'queries';
import { Permissions, useStyles } from './Permissions.style';

/**
 * Component
 * @param {object} props Incoming props
 * @returns {XML} Component
 */
const PermissionsComponent = () => {
  const [, ranglrDispatch] = useRanglrContext();
  const classes = useStyles();
  const { data: { getEmployees: employees = [] } = {} } = useQuery(GET_ALL_EMPLOYEES);
  const { data: { getPermissions: permissions = [] } = {} } = useQuery(GET_PERMISSIONS);

  const [savePermissions, { data }] = useMutation(SAVE_PERMISSION);

  useEffect(() => {
    if (data) {
      ranglrDispatch({ type: 'SET_MESSAGE', payload: { variant: 'success', message: 'Saved' } });
    }
  }, [data, ranglrDispatch]);

  const lookup = {};
  roles.forEach(r => {
    lookup[r.key] = r.display;
  });

  const employeeList = employees
    .filter(e => e.is_active)
    .map(e => {
      const newE = { ...e };
      const userPermissions = permissions.filter(p => p.id === newE.id);
      if (userPermissions[0]) {
        newE.level = userPermissions[0].level;
      } else {
        newE.level = 'staff';
      }
      return newE;
    });

  const handleChange = editedEmployee => {
    const { id, level } = editedEmployee;
    savePermissions({
      variables: {
        id,
        level,
      },
      refetchQueries: [
        {
          query: GET_PERMISSIONS,
        },
      ],
    });
  };

  return (
    <Permissions>
      <Table
        title="Permissions"
        editable={{
          onRowUpdate: newData =>
            new Promise(resolve => {
              handleChange(newData);
              resolve();
            }),
        }}
        columns={[
          { title: 'Last Name', field: 'last_name', editable: 'never', defaultSort: 'asc' },
          { title: 'First Name', field: 'first_name', editable: 'never' },
          { title: 'Email', field: 'email', editable: 'never' },
          {
            title: 'Permission Level',
            field: 'level',
            lookup,
          },
        ]}
        data={employeeList}
        styles={{
          toolbar: classes.toolbar,
        }}
        options={{
          pageSize: 20,
        }}
      />
    </Permissions>
  );
};

export { Permissions as PermissionsVanlla };
export default React.memo(PermissionsComponent);
