import { colors } from '../colors';

export default {
  text: {
    background: 'white',
    borderRadius: 6,
    border: `2px solid ${colors.grey.main}`,
    color: colors.secondary.main,
    height: 44,
    padding: '0 20px',
  },
  sizeSmall: {
    height: 30,
    padding: '0 15px',
  },
}
