import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  TextField,
  Zoom,
  Paper,
  Button,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { useEstimateContext } from 'context/provider.ranglr';
import { getParsedObject } from 'utils/formatters';
import useEstimateUtils from 'utils/useEstimateUtils';
import EstimateGroup from 'components/molecules/EstimateGroup';
import { Link } from '@reach/router';
import StoryListingModal from '../StoryListingModal';
import EstimateMetrics from '../EstimateMetrics';

import {
  EstimateConfigurator,
  Header,
  Footer,
  MarginFab,
  FabWrapper,
  useStyles,
} from './EstimateConfigurator.style';

const EstimateConfiguratorComponent = () => {
  const classes = useStyles();
  const [groupName, setGroupName] = useState('');
  const theme = useTheme();
  const {
    addGroup,
    setDiscount,
    setActiveEstimate,
    setBlendedRate,
  } = useEstimateUtils();
  const [estimateContext] = useEstimateContext();
  const { currentEstimate = {} } = estimateContext;
  const groups = getParsedObject(currentEstimate, 'groups', []);
  const { discount, isApproved, blendedRate } = currentEstimate;
  const addNewGroup = () => {
    const tempName = groupName;
    setGroupName('');
    addGroup(tempName);
  };
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {  // eslint-disable-line arrow-body-style
    return () => { // eslint-disable-line arrow-body-style
      setActiveEstimate(null);
    };
  }, []);

  if (!currentEstimate || !currentEstimate.id) return null;
  return (
    <EstimateConfigurator>
      <Paper className={classes.effortWrapper}>
        <EstimateMetrics />
        {!isApproved && (
          <>
            <TextField
              id="blendedRate"
              label="Blended Rate"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={blendedRate}
              margin="dense"
              variant="outlined"
              className={classes.marginRight}
              onChange={e => {
                const newBlendedRate = e.target.value;
                if (newBlendedRate && parseInt(newBlendedRate)) {
                  setBlendedRate(parseInt(newBlendedRate));
                } else {
                  setBlendedRate();
                }
              }}
            />
            <TextField
              id="percentageDiscount"
              label="Percentage Discount"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={discount}
              margin="dense"
              variant="outlined"
              onChange={e => {
                const newDiscount = e.target.value;
                if (
                  newDiscount &&
                  parseInt(newDiscount) &&
                  parseInt(newDiscount) >= 0 &&
                  parseInt(newDiscount) <= 100
                ) {
                  setDiscount(parseInt(newDiscount));
                } else {
                  setDiscount();
                }
              }}
            />
          </>
        )}
      </Paper>
      <Header>
        <Grid container className="title" justify="space-between">
          <Grid container xs={3} direction="row" alignItems="flex-start">
            <Typography>Title</Typography>
          </Grid>
          <Grid container xs={4}>
            <Typography>Description</Typography>
          </Grid>
          <Grid container xs={1} alignItems="center" justify="center">
            <Typography>Low</Typography>
          </Grid>
          <Grid container xs={1} alignItems="center" justify="center">
            <Typography>High</Typography>
          </Grid>
          <Grid container xs={1} alignItems="center" justify="center">
            <Typography>Multiplier</Typography>
          </Grid>
          <Grid container xs={1} alignItems="center" justify="center">
            <Typography>Edit</Typography>
          </Grid>
          <Grid container xs={1} alignItems="center" justify="center">
            <Typography>Delete</Typography>
          </Grid>
        </Grid>
      </Header>
      {groups.map((g, i) => (
        <EstimateGroup key={g.id} index={i} group={g} isApproved={isApproved} />
      ))}
      {!isApproved && (
        <Footer>
          <Grid container xs={12} direction="row" alignItems="center">
            <Grid item xs={3}>
              <TextField
                id="outlined-with-placeholder"
                label="New Group Name"
                placeholder="New Group"
                onChange={e => setGroupName(e.target.value)}
                margin="normal"
                variant="outlined"
                value={groupName}
                style={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <FabWrapper>
                <Zoom
                  in={groupName.length > 3}
                  timeout={transitionDuration}
                  style={{
                    transitionDelay: `${
                      groupName.length > 3 ? transitionDuration.exit : 0
                    }ms`,
                  }}
                  unmountOnExit
                >
                  <MarginFab
                    size="small"
                    color="secondary"
                    aria-label="add"
                    onClick={addNewGroup}
                  >
                    <Add />
                  </MarginFab>
                </Zoom>
              </FabWrapper>
            </Grid>
          </Grid>
        </Footer>
      )}

      <br />
      {currentEstimate.id && (
        <Button
          component={Link}
          to={`/estimate-accepted/${currentEstimate.id}`}
        >
          Go to Estimate Summary
        </Button>
      )}
      <StoryListingModal />
    </EstimateConfigurator>
  );
};

export { EstimateConfigurator as EstimateConfiguratorVanlla };
export default EstimateConfiguratorComponent;
